import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import EditableTextInput from './EditableTextInput';
import { CDN_URL } from '../utils/cdn';
import ChatPreview from './LargeAvatar';
import TextButton from './TextButton';
import EditIcon from '../../assets/svg/edit.svg';
import WaveIcon from '../../assets/svg/wave.svg';
import UploadIcon from '../../assets/svg/upload.svg';
import DeleteIcon from '../../assets/svg/delete.svg';
import FilledButton, { FilledButtonStyles } from './FilledButton';
import SignupCta from './SignupCta';
import LabeledToggleSwitch from './LabeledToggleSwitch';
import Dropdown from './Dropdown';
import DropdownList from './DropdownList';
import DropdownContents from './DropdownContents';
import DropdownListItem from './DropdownListItem';
import LargeAvatar from './LargeAvatar';

interface AccountPanel {
    className?: string;
    name: string;
    userID?: string;
    avatarUrl?: string;
    email?: string;
    mobile?: boolean;
    fullbleed?: boolean;
    chatNotificationsEnabled: boolean;
    onChangeName?: (name: string) => void;
    onClickNameInput?: () => void;
    onLogout?: () => void;
    onSignup?: () => void;
    onLogin?: () => void;
    onAvatarFileChange?: (objectURL: string) => void;
    onEditAvatar?: () => void;
    onRemoveAvatar?: () => void;
    onToggleChatNotifications: () => void;
}

const AccountPanel: React.FunctionComponent<AccountPanel> = (props) => {
    const {
        className,
        name,
        userID,
        avatarUrl,
        email,
        mobile = false,
        fullbleed = false,
        chatNotificationsEnabled,
        onClickNameInput,
        onChangeName,
        onLogout,
        onSignup,
        onLogin,
        onAvatarFileChange,
        onEditAvatar,
        onRemoveAvatar,
        onToggleChatNotifications,
    } = props;

    const uploadLabel = useRef<HTMLLabelElement>(null);
    const avatarContainer = useRef<HTMLButtonElement>(null);

    const [avatarDropdownVisible, setAvatarDropdownVisible] = useState(false);

    return (
        <AccountPanelStyled className={className} fullbleed={fullbleed}>
            <InnerStyled>
                {userID ? (
                    <>
                        <SectionStyled>
                            <AvatarSectionStyled mobile={mobile}>
                                <AvatarContainerStyled
                                    ref={avatarContainer}
                                    onClick={() => {
                                        if (avatarUrl) {
                                            setAvatarDropdownVisible(!avatarDropdownVisible);
                                        } else {
                                            uploadLabel.current.click();
                                        }
                                    }}
                                >
                                    <AvatarStyled
                                        avatarUrl={avatarUrl}
                                        size={mobile ? 160 : 120}
                                        mobile={mobile}
                                    />
                                    <AvatarEditButtonStyled
                                        format="neutral"
                                        size={mobile ? 'lg' : 'sm'}
                                        icon
                                    >
                                        {avatarUrl ? <EditIcon /> : <UploadIcon />}
                                    </AvatarEditButtonStyled>
                                </AvatarContainerStyled>
                                <Dropdown
                                    open={avatarDropdownVisible}
                                    setOpen={setAvatarDropdownVisible}
                                    align="center"
                                    vertical="bottom"
                                    mobile={mobile}
                                    clickElements={[avatarContainer.current]}
                                >
                                    <DropdownContents
                                        mobile={mobile}
                                        onClick={() => {
                                            setAvatarDropdownVisible(false);
                                        }}
                                    >
                                        <DropdownList mobile={mobile}>
                                            <label
                                                style={{ cursor: 'pointer' }}
                                                htmlFor="user-avatar-input"
                                            >
                                                <DropdownListItem
                                                    mobile={mobile}
                                                    onClick={() => {
                                                        // noop
                                                    }}
                                                >
                                                    {avatarUrl ? 'Change avatar' : 'Upload avatar'}
                                                    <UploadIcon />
                                                </DropdownListItem>
                                            </label>
                                            <DropdownListItem
                                                mobile={mobile}
                                                onClick={onEditAvatar}
                                            >
                                                Crop image
                                                <EditIcon />
                                            </DropdownListItem>
                                            <RemoveAvatarButtonStyled
                                                mobile={mobile}
                                                onClick={onRemoveAvatar}
                                            >
                                                Remove
                                                <DeleteIcon />
                                            </RemoveAvatarButtonStyled>
                                        </DropdownList>
                                    </DropdownContents>
                                </Dropdown>
                                <label
                                    ref={uploadLabel}
                                    style={{ cursor: 'pointer', display: 'none' }}
                                    htmlFor="user-avatar-input"
                                />
                                <input
                                    id="user-avatar-input"
                                    onChange={(e) => {
                                        if (e.target.files.length !== 1) {
                                            console.error('there should be 1 file selected');
                                            return;
                                        }

                                        const file = e.target.files[0];
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            onAvatarFileChange(reader.result as string);
                                        };
                                        reader.readAsDataURL(file);
                                    }}
                                    onClick={(e) => {
                                        e.currentTarget.value = null;
                                    }}
                                    style={{ display: 'none' }}
                                    type="file"
                                    multiple={false}
                                    accept="image/*"
                                />
                            </AvatarSectionStyled>
                            <EditableTextInputStyled
                                className="tooltip_parent"
                                label="Username"
                                name="username"
                                value={name}
                                disabled={!userID}
                                maxLength={32}
                                minLength={2}
                                spellCheck="false"
                                onSave={onChangeName}
                                onClick={onClickNameInput}
                            />
                        </SectionStyled>
                        <SectionStyled>
                            <SectionHeadingStyled>Email</SectionHeadingStyled>
                            <EmailStyled>{email}</EmailStyled>
                        </SectionStyled>
                        <SectionStyled>
                            <SectionHeadingStyled>Room settings</SectionHeadingStyled>
                            <LabeledToggleSwitchStyled
                                reverse
                                title="Chat notifications"
                                id="notification_toggle"
                                enabled={chatNotificationsEnabled}
                                onChange={onToggleChatNotifications}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            />
                        </SectionStyled>
                        <LogoutButtonStyled format="secondary" onClick={onLogout}>
                            <WaveIcon />
                            Log out
                        </LogoutButtonStyled>
                    </>
                ) : (
                    <>
                        <AppIconStyled src={`${CDN_URL}/images/app-icon-128.png`} alt="Playback" />
                        <LoggedOutHeadingStyled>
                            Create your Playback account
                        </LoggedOutHeadingStyled>
                        <ListStyled>
                            <ListItemStyled>Unlock stream audio and room chat</ListItemStyled>
                            <ListItemStyled>Change your username and avatar</ListItemStyled>
                            <ListItemStyled>Create and stream to your own rooms</ListItemStyled>
                        </ListStyled>
                        <SignupCta
                            format="negative_primary"
                            onSignup={onSignup}
                            onLogin={onLogin}
                        />
                    </>
                )}
            </InnerStyled>
        </AccountPanelStyled>
    );
};

const RemoveAvatarButtonStyled = styled(DropdownListItem)`
    color: ${({ theme }) => theme.palette.Red};

    @media (hover: hover) {
        &:hover:not(:disabled) {
            color: ${({ theme }) => theme.palette.Red};
        }
    }
`;

const LogoutButtonStyled = styled(TextButton)`
    margin-top: auto;
    ${({ theme }) => theme.typography.Heading16}
    color: ${({ theme }) => theme.palette.LightGrey1};

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
    }
`;

const EditableTextInputStyled = styled(EditableTextInput)`
    label {
        margin-bottom: ${rem(8)};
    }
`;

const LabeledToggleSwitchStyled = styled(LabeledToggleSwitch)`
    justify-content: space-between;
    ${({ theme }) => theme.typography.Heading16}
`;

const ListItemStyled = styled.li`
    list-style-type: disc;
    ${({ theme }) => theme.typography.Paragraph13}
    color: ${({ theme }) => theme.palette.LightGrey1};
    line-height: 1.5;

    &::marker {
        margin-right: ${rem(5)};
    }

    & + & {
        margin-top: ${rem(4)};
    }
`;

const ListStyled = styled.ul`
    margin-bottom: ${rem(16)};
    margin-left: ${rem(16)};
`;

const LoggedOutHeadingStyled = styled.h4`
    ${({ theme }) => theme.typography.FontMedium}
    color: ${({ theme }) => theme.palette.White};
    font-size: ${rem(16)};
    margin-bottom: ${rem(12)};
`;

const AppIconStyled = styled.img`
    height: ${rem(40)};
    width: ${rem(40)};
    margin-bottom: ${rem(16)};
`;

const AvatarEditButtonStyled = styled.div<FilledButton>`
    ${FilledButtonStyles}
    box-shadow: ${rem(-1)} 0 ${rem(2)} rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: visible;
    z-index: 2;
`;

const AvatarContainerStyled = styled.button`
    position: relative;

    @media (hover: hover) {
        &:hover {
            ${AvatarEditButtonStyled} {
                background-color: ${({ theme }) => theme.palette.MedGrey3};
            }
        }
    }
`;

const AvatarSectionStyled = styled.div<{ mobile: boolean }>`
    position: relative;
    margin-bottom: ${rem(20)};
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;

    ${({ mobile }) =>
        mobile &&
        css`
            margin-bottom: ${rem(36)};
            flex-direction: column;
        `}
`;

const AvatarStyled = styled(LargeAvatar)`
    border-radius: ${rem(2)};
`;

const EmailStyled = styled.p`
    ${({ theme }) => theme.typography.Heading16}
`;

const SectionHeadingStyled = styled.h4`
    font-size: ${rem(13)};
    color: ${({ theme }) => theme.palette.LightGrey1};
    margin-bottom: ${rem(8)};
`;

const SectionStyled = styled.div`
    margin-bottom: ${rem(32)};
`;

const InnerStyled = styled.div`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
`;

const AccountPanelStyled = styled.div<{ fullbleed: boolean }>`
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    ${({ fullbleed }) =>
        fullbleed &&
        css`
            height: 100%;
        `}
`;

export default AccountPanel;
