import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import FilledButton from './FilledButton';
import TextButton from './TextButton';

interface SignupCta {
    className?: string;
    style?: React.HTMLAttributes<HTMLDivElement>['style'];
    cta?: 'signup' | 'login';
    size?: FilledButton['size'];
    signupText?: string;
    loginText?: string;
    format?: FilledButton['format'];
    onSignup: () => void;
    onLogin: () => void;
}

const SignupCta: React.FunctionComponent<SignupCta> = (props) => {
    const {
        className,
        style,
        format = 'success',
        cta = 'signup',
        size = 'lg',
        signupText,
        loginText,
        children,
        onSignup,
        onLogin,
    } = props;

    const defaultCta = cta === 'signup' ? signupText || 'Sign up' : loginText || 'Log in';
    const altCtaPrompt = cta === 'signup' ? 'Already signed up?' : 'Need an account';
    const altCta = cta === 'signup' ? 'Log in' : 'Sign up';

    const onClickPrimaryCta = cta === 'signup' ? onSignup : onLogin;
    const onClickAltCta = cta === 'signup' ? onLogin : onSignup;

    return (
        <SignupCtaStyled className={className} style={style}>
            <PrimaryCtaButtonStyled
                type="button"
                format={format}
                size={size}
                onClick={onClickPrimaryCta}
            >
                {children || defaultCta}
            </PrimaryCtaButtonStyled>
            <AltCtaStyled>
                {altCtaPrompt}{' '}
                <TextButton type="button" onClick={onClickAltCta}>
                    {altCta}
                </TextButton>
            </AltCtaStyled>
        </SignupCtaStyled>
    );
};

const AltCtaStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}
    text-align: center;
    margin-top: ${rem(12)};
    align-self: center;
`;

const PrimaryCtaButtonStyled = styled(FilledButton)`
    align-self: stretch;
`;

const SignupCtaStyled = styled.div`
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default SignupCta;
