import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import StarIcon from '../../assets/svg/star.svg';

interface IFiveStars {
    className?: string;
    selected: number;
    onClick: (stars: number) => void;
}

const FiveStars: React.FunctionComponent<IFiveStars> = (props) => {
    const { className, selected, onClick } = props;

    const [hoveredCount, setHoveredCount] = useState(0);

    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <StarStyled
                key={`star_${i}`}
                type="button"
                title={`${i} star`}
                selected={hoveredCount ? i <= hoveredCount : i <= selected}
                onClick={() => onClick(selected === i ? 0 : i)}
                onMouseEnter={() => {
                    setHoveredCount(i);
                }}
                onMouseLeave={() => {
                    setHoveredCount(0);
                }}
            >
                <StarIcon />
            </StarStyled>
        );
    }

    return <FiveStarsStyled className={className}>{stars}</FiveStarsStyled>;
};

const StarStyled = styled.button<{ selected: boolean }>`
    height: ${rem(28)};
    width: ${rem(44)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.Black};

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.Yellow};
        }
    }

    ${({ selected, theme }) =>
        selected &&
        css`
            color: ${theme.palette.Yellow};
        `}

    svg {
        height: ${rem(28)};
        width: ${rem(28)};
    }
`;

const FiveStarsStyled = styled.div`
    display: flex;
    align-items: center;
`;

export default FiveStars;
