import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import BackButton from 'packages/common/base/components/BackButton';

interface Screen {
    className?: string;
    visible: boolean;
    canGoBack?: boolean;
    header: string | React.ReactNode;
    onClickBack?: () => void;
}

const Screen: React.FunctionComponent<Screen> = (props) => {
    const { className, visible, canGoBack = false, header, children, onClickBack } = props;

    return (
        <ScreenStyled visible={visible}>
            <HeaderStyled>
                {canGoBack && <BackButtonStyled size="lg" onClick={onClickBack} />}
                {typeof header === 'string' ? (
                    <HeaderTextStyled>{header}</HeaderTextStyled>
                ) : (
                    header
                )}
            </HeaderStyled>
            <MainStyled>
                <ContentsStyled className={className}>{children}</ContentsStyled>
            </MainStyled>
        </ScreenStyled>
    );
};

const BackButtonStyled = styled(BackButton)`
    position: absolute;
    top: ${rem(12)};
    left: ${rem(16)};
`;

const MainStyled = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const ContentsStyled = styled.div`
    flex: 1;
    padding: ${rem(16)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    min-height: 100%;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(20)};
    }
`;

const HeaderTextStyled = styled.h3`
    ${({ theme }) => theme.typography.Heading16}
`;

const HeaderStyled = styled.div`
    padding: 0 ${rem(64)};
    height: ${rem(64)};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

const ScreenStyled = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
`;

export default Screen;
