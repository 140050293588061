import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { useDetectClickOutside } from '../hooks';
import { useSwipeable } from 'react-swipeable';
import useShowWhenVisible from '../hooks/useShowWhenVisible';

interface Dropdown {
    className?: string;
    open: boolean;
    align?: 'left' | 'center' | 'right';
    vertical?: 'top' | 'bottom';
    mobile?: boolean;
    swallowClicks?: boolean;
    clickElements?: HTMLElement[];
    swipeToDismiss?: boolean;
    setOpen: (open: boolean) => void;
    children: React.ReactNode | React.ReactNode[];
}

const Dropdown: React.FunctionComponent<Dropdown> = (props) => {
    const {
        className,
        open,
        mobile = false,
        align = 'center',
        vertical = 'bottom',
        setOpen,
        swipeToDismiss = true,
        clickElements = [],
        swallowClicks = false,
        children,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(open);

    const swipeHandlers = useSwipeable({
        onSwipedDown: () => {
            if (swipeToDismiss) {
                setOpen(false);
            }
        },
    });

    useDetectClickOutside({
        mobile,
        swallowClicks,
        elements: [ref.current, ...clickElements],
        escapeKey: true,
        enabled: open && !mobile,
        onClickOutside: useCallback(() => {
            setOpen(false);
        }, [setOpen]),
    });

    useEffect(() => {
        setVisible(open);
    }, [open]);

    const isShowing = useShowWhenVisible({ visible, duration: 150 });

    if (!isShowing && !open) {
        return null;
    }

    return (
        <DropdownStyled
            open={visible}
            className={className}
            ref={ref}
            mobile={mobile}
            align={align}
            vertical={vertical}
            {...(mobile ? swipeHandlers : {})}
            onClick={(e) => {
                if (mobile && e.target === e.currentTarget) {
                    e.stopPropagation();
                    setOpen(false);
                }
            }}
        >
            <InnerStyled mobile={mobile}>
                {children}
                {mobile && <SwipeBarStyled />}
            </InnerStyled>
        </DropdownStyled>
    );
};

const SwipeBarStyled = styled.div`
    width: ${rem(120)};
    height: ${rem(4)};
    border-radius: ${rem(3)};
    background-color: ${({ theme }) => theme.palette.White};
    opacity: 0.5;
    position: absolute;
    bottom: calc(100% + ${rem(8)});
    left: 50%;
    transform: translateX(-50%);
`;

const InnerStyled = styled.div<{ mobile: boolean }>`
    position: relative;
    transition: transform 100ms ease-out;
    width: 100%;

    ${({ mobile }) =>
        mobile &&
        css`
            position: fixed;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0;
            max-height: calc(100vh - ${rem(40)});
            overflow-y: auto;
        `}
`;

const DropdownStyled = styled.div<{
    open: boolean;
    mobile: boolean;
    align: Dropdown['align'];
    vertical: Dropdown['vertical'];
}>`
    position: ${({ mobile }) => (mobile ? 'fixed' : 'absolute')};
    top: ${({ vertical }) => (vertical === 'bottom' ? '100%' : 'auto')};
    bottom: ${({ vertical }) => (vertical === 'top' ? '100%' : 'auto')};
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 150ms ease-out, background-color 150ms ease-out, visibility 0ms linear 150ms;
    overflow: hidden;
    z-index: 2;
    padding-top: ${({ mobile }) => rem(mobile ? 0 : 12)};
    padding-bottom: ${({ mobile }) => rem(mobile ? 0 : 12)};
    background-color: transparent;

    ${({ mobile, align }) => {
        if (!mobile) {
            switch (align) {
                case 'left':
                    return css`
                        left: 0;
                        right: auto;
                        width: auto;
                    `;
                case 'right':
                    return css`
                        right: 0;
                        left: auto;
                        width: auto;
                    `;
                default:
                    return css`
                        left: 50%;
                        right: auto;
                        width: auto;
                        transform: translateX(-50%);
                        padding: ${rem(12)};
                    `;
            }
        }
    }}

    ${InnerStyled} {
        transform: translateY(${({ vertical }) => (vertical === 'top' ? '100%' : '-100%')});
    }

    ${({ mobile }) =>
        mobile &&
        css`
            position: fixed;
            top: 0;
            bottom: 0;

            ${InnerStyled} {
                transform: translateY(100%);
            }
        `}

    ${({ open, mobile }) =>
        open &&
        css`
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
            background-color: ${mobile ? 'rgba(0, 0, 0, 0.75)' : 'transparent'};
            transition: opacity 150ms ease-in, background-color 150ms ease-in,
                visibility 0ms linear 0s;

            ${InnerStyled} {
                transform: translateY(0);
                transition: transform 150ms ease-in;
            }
        `}
`;

export default Dropdown;
