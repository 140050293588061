import React, { MutableRefObject, useEffect, useState } from 'react';
import Screen from './Screen';
import SubscriptionList from './subscriptions/SubscriptionList';
import SubscriptionPicker from './subscriptions/SubscriptionPicker';
import SubscriptionAuth, { SubscriptionAuthStep } from './subscriptions/SubscriptionAuth';
import useAuth from '../../hooks/useAuth';
import { PlatformAuthConfig, platforms } from '../../constants/tvAuth';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { dismissModal, hideAccountPanel, showNotification, track } from '../../state/app';
import { platformsToNames, SubscriptionTypes } from 'packages/common/tv-auth/platforms';
import {
    tvAuthSubscriptionsGET,
    tvAuthSubscriptionDELETE,
} from 'packages/common/api-client/tvAuth';
import { API_HOST } from '../../typescript/api';
import { setTvSubscriptions } from 'packages/common/state/user';
import { TVAuthSubscription } from 'packages/common/api-client/types';
import { useRouter } from 'next/router';
import { ModalType } from '../../typescript/typings';
import { Client } from 'packages/common/tv-auth/client';
import { NotificationType } from 'packages/common/base/types';
import { toast } from 'react-toastify';

interface Subscriptions {
    screen: SubscriptionsScreen;
    visible: boolean;
    authClient: MutableRefObject<Client>;
    onClose: () => void;
    onExit: () => void;
    setScreen: (screen: SubscriptionsScreen) => void;
}

export enum SubscriptionsScreen {
    List,
    Picker,
    Auth,
}

const Subscriptions: React.FunctionComponent<Subscriptions> = (props) => {
    const { screen, visible, authClient, onClose, onExit, setScreen } = props;

    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const router = useRouter();
    const alwaysShowPortal = router.query.alwaysShowPortal === '1';
    const useLocal = router.query.useLocal === '1';

    const modalVisible = useAppSelector(
        (state) => state.app.modal?.type === ModalType.PermissiveTvAuth
    );

    const [selectedPlatform, setSelectedPlatform] = useState<PlatformAuthConfig>(null);
    const [authStep, setAuthStep] = useState<SubscriptionAuthStep>(SubscriptionAuthStep.Login);

    const getAuthHeading = () => {
        switch (authStep) {
            case SubscriptionAuthStep.Connecting:
                return 'Connecting';
            case SubscriptionAuthStep.Portal:
                return 'Additional steps required';
            case SubscriptionAuthStep.Verifying:
                return 'Verifying access';
            case SubscriptionAuthStep.Blocked:
                return 'Unable to verify access';
            default:
                return 'Connect your account';
        }
    };

    const updateSubscriptions = async () => {
        try {
            const subscriptions = await tvAuthSubscriptionsGET(user.accessToken, API_HOST);
            dispatch(setTvSubscriptions(subscriptions));
        } catch (e) {
            dispatch(track({ event: 'Error Updating TV Subscriptions', error: e }));
        }
    };

    const disconnectSubscription = async (subscription: TVAuthSubscription) => {
        dispatch(
            track({
                event: 'Disconnect TV Subscription',
                platform: platformsToNames[subscription.platform],
            })
        );

        try {
            await tvAuthSubscriptionDELETE(
                user.accessToken,
                { platform: subscription.platform },
                API_HOST
            );
            dispatch(
                setTvSubscriptions(user.tvSubscriptions.filter((sub) => sub.id !== subscription.id))
            );
        } catch (e) {
            dispatch(
                track({
                    event: 'Error Disconnecting TV Subscription',
                    platform: platformsToNames[subscription.platform],
                    error: e,
                })
            );
        }
    };

    useEffect(() => {
        if (visible && modalVisible) {
            setScreen(SubscriptionsScreen.Picker);
            dispatch(dismissModal(ModalType.PermissiveTvAuth));
        }
    }, [dispatch, visible, modalVisible, setScreen]);

    useEffect(() => {
        if (visible) return;

        if (authStep === SubscriptionAuthStep.Blocked) {
            setScreen(SubscriptionsScreen.List);
            setAuthStep(SubscriptionAuthStep.Login);
            dispatch(
                showNotification({
                    type: NotificationType.TvAuthAccessDenied,
                    payload: platformsToNames[selectedPlatform.platform],
                })
            );
        } else if (authStep === SubscriptionAuthStep.Verified) {
            setScreen(SubscriptionsScreen.List);
            setAuthStep(SubscriptionAuthStep.Login);
            dispatch(
                showNotification({
                    type: NotificationType.TvAuthVerified,
                    payload: platformsToNames[selectedPlatform.platform],
                })
            );
        }
    }, [authStep, visible]);

    return (
        <Screen
            canGoBack
            visible={visible}
            header={selectedPlatform === null ? 'TV Subscriptions' : getAuthHeading()}
            onClickBack={() => {
                switch (screen) {
                    case SubscriptionsScreen.Auth:
                        if (authStep === SubscriptionAuthStep.Verified) {
                            setScreen(SubscriptionsScreen.List);
                            setSelectedPlatform(null);
                            setAuthStep(SubscriptionAuthStep.Login);
                        } else if (
                            authStep === SubscriptionAuthStep.Login ||
                            authStep === SubscriptionAuthStep.Blocked
                        ) {
                            setScreen(SubscriptionsScreen.Picker);
                            setSelectedPlatform(null);
                            setAuthStep(SubscriptionAuthStep.Login);
                        } else {
                            dispatch(track({ event: 'Abort TV Subscription Login' }));
                            setAuthStep(SubscriptionAuthStep.Login);
                        }

                        authClient.current?.close();

                        break;
                    case SubscriptionsScreen.Picker:
                        setScreen(SubscriptionsScreen.List);
                        break;
                    default:
                        onClose();
                }
            }}
        >
            {screen === SubscriptionsScreen.List && (
                <SubscriptionList
                    subscriptions={user.tvSubscriptions}
                    onClickAdd={() => {
                        dispatch(track({ event: 'Add TV Subscription' }));
                        setScreen(SubscriptionsScreen.Picker);
                    }}
                    onDisconnect={disconnectSubscription}
                />
            )}
            {screen === SubscriptionsScreen.Picker && (
                <SubscriptionPicker
                    subscriptions={user.tvSubscriptions}
                    onSelect={(platform) => {
                        dispatch(
                            track({
                                event: 'Select TV Platform',
                                platform: platformsToNames[platform.platform],
                            })
                        );
                        setSelectedPlatform(platform);
                        setScreen(SubscriptionsScreen.Auth);
                    }}
                    onDisconnect={disconnectSubscription}
                />
            )}
            {screen === SubscriptionsScreen.Auth && (
                <SubscriptionAuth
                    client={authClient}
                    platform={selectedPlatform}
                    step={authStep}
                    alwaysShowPortal={alwaysShowPortal}
                    useLocal={useLocal}
                    onAddMore={() => {
                        dispatch(track({ event: 'Add More TV Subscriptions' }));
                        setScreen(SubscriptionsScreen.Picker);
                        setAuthStep(SubscriptionAuthStep.Login);
                        setSelectedPlatform(null);
                    }}
                    onExit={() => {
                        dispatch(track({ event: 'Exit TV Subscription Picker' }));
                        setScreen(SubscriptionsScreen.List);
                        setAuthStep(SubscriptionAuthStep.Login);
                        onExit();
                    }}
                    onConnectStart={() => {
                        dispatch(
                            track({
                                event: 'Submit TV Subscription Credentials',
                                platform: platformsToNames[selectedPlatform.platform],
                            })
                        );
                        setAuthStep(SubscriptionAuthStep.Connecting);
                    }}
                    onHidePortal={() => {
                        setAuthStep(SubscriptionAuthStep.Connecting);
                    }}
                    onShowPortal={() => {
                        dispatch(
                            track({
                                event: 'Show TV Subscription Portal',
                                platform: platformsToNames[selectedPlatform.platform],
                            })
                        );
                        setAuthStep(SubscriptionAuthStep.Portal);
                    }}
                    onError={(message) => {
                        dispatch(
                            track({
                                event: 'TV Subscription Login Error',
                                platform: platformsToNames[selectedPlatform.platform],
                                errorMessage: message,
                            })
                        );
                        if (!alwaysShowPortal) {
                            setAuthStep(SubscriptionAuthStep.Login);
                        }
                    }}
                    onConnected={() => {
                        dispatch(
                            track({
                                event: 'TV Subscription Connected',
                                platform: platformsToNames[selectedPlatform.platform],
                            })
                        );
                        dispatch(hideAccountPanel());
                        dispatch(
                            showNotification({
                                type: NotificationType.TvAuthConnected,
                                payload: platformsToNames[selectedPlatform.platform],
                            })
                        );
                        setAuthStep(SubscriptionAuthStep.Verifying);
                    }}
                    onSubscribed={(subType, subAddons, subLocation) => {
                        authClient.current?.close();
                        toast.dismiss(NotificationType.TvAuthConnected);

                        if (subType === SubscriptionTypes.None) {
                            dispatch(
                                track({
                                    event: 'TV Subscription Not Included',
                                    platform: platformsToNames[selectedPlatform.platform],
                                    subType,
                                    subAddons,
                                    subLocation,
                                })
                            );

                            setAuthStep(SubscriptionAuthStep.Blocked);
                        } else {
                            dispatch(
                                track({
                                    event: 'TV Subscription Verified',
                                    platform: platformsToNames[selectedPlatform.platform],
                                    subType,
                                    subAddons,
                                    subLocation,
                                })
                            );

                            updateSubscriptions();
                            setAuthStep(SubscriptionAuthStep.Verified);
                        }
                    }}
                />
            )}
        </Screen>
    );
};

export default Subscriptions;
