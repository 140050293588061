import React, { forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import FilledButton from './FilledButton';
import CheckSelectInput from './CheckSelectInput';
import FiveStars from './FiveStars';
import TextArea from './TextArea';
import { CDN_URL } from '../utils/cdn';

interface FeedbackModule {
    className?: string;
    visible: boolean;
    title?: boolean;
    app: 'web' | 'mac' | 'windows';
    userID?: string;
    participantID: string;
    userName: string;
    roomUrlName: string;
    steps: FeedbackConfig;
    mobile?: boolean;
    additionalFields?: any;
    onSubmit: (feedback: any) => void;
}

export type FeedbackConfig = {
    rating: {
        heading: string;
    };
    feedback: {
        heading: string;
    };
    issues: {
        optionsHeading: string;
        options: string[];
    };
    thankYou: {
        heading: string;
        subheading: string;
    };
};

const FeedbackModule: React.ForwardRefRenderFunction<HTMLDivElement, FeedbackModule> = (
    props,
    ref
) => {
    const {
        className,
        title,
        visible,
        app,
        userID,
        participantID,
        userName,
        roomUrlName,
        steps,
        mobile = false,
        additionalFields = {},
        onSubmit,
    } = props;

    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [issues, setIssues] = useState(() => new Set<string>());
    const [submitted, setSubmitted] = useState(false);

    const getSubmitData = () => ({
        name: userName,
        rating,
        feedback,
        userID,
        participantID,
        roomUrlName,
        issues: Array.from(issues).join(', '),
        app,
        mobile,
        ...additionalFields,
    });

    useEffect(() => {
        if (visible) {
            setRating(0);
            setFeedback('');
            setIssues(new Set<string>());
            setSubmitted(false);
        }
    }, [visible]);

    return (
        <FeedbackModuleStyled className={className} ref={ref}>
            <InnerStyled
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(getSubmitData());
                    setSubmitted(true);
                }}
                noValidate
            >
                {submitted ? (
                    <SectionStyled style={{ minWidth: 280 }}>
                        <PrimaryHeadingStyled>{steps.thankYou.heading}</PrimaryHeadingStyled>
                        <PrimarySubheadingStyled>
                            {steps.thankYou.subheading}
                        </PrimarySubheadingStyled>
                        <ThankYouGifStyled
                            src={`${CDN_URL}/gifs/reid_thank_you.gif`}
                            alt="Andy Reid says thanks"
                        />
                    </SectionStyled>
                ) : (
                    <>
                        {title && <PrimaryHeadingStyled>Share your feedback</PrimaryHeadingStyled>}
                        <PrimarySubheadingStyled>
                            If you’re having trouble, have a feature request, or just want to tell
                            us how much fun you’re having, we’d love to hear about it!
                        </PrimarySubheadingStyled>
                        <SectionStyled>
                            <HeadingStyled>{steps.rating.heading}</HeadingStyled>
                            <FiveStarsStyled
                                selected={rating}
                                onClick={(rating) => {
                                    setRating(rating);
                                }}
                            />
                        </SectionStyled>
                        <SectionStyled>
                            <HeadingStyled>{steps.issues.optionsHeading}</HeadingStyled>
                            {steps.issues.options.map((option, i) => (
                                <OptionStyled
                                    name={option}
                                    label={option}
                                    key={`option_${i}`}
                                    format="negative"
                                    id={`option_${i}`}
                                    checked={issues.has(option)}
                                    onChange={(e) => {
                                        const temp = new Set(issues);
                                        if (e.target.checked) {
                                            temp.add(option);
                                        } else {
                                            temp.delete(option);
                                        }

                                        setIssues(temp);
                                    }}
                                />
                            ))}
                        </SectionStyled>
                        <SectionStyled>
                            <HeadingStyled>{steps.feedback.heading}</HeadingStyled>
                            <TextAreaStyled
                                name="Feedback"
                                value={feedback}
                                onChange={(e) => {
                                    setFeedback(e.target.value);
                                }}
                                placeholder="Please provide any additional details, context, or feedback."
                                spellCheck={false}
                            />
                            <SubmitButtonStyled
                                type="submit"
                                size="lg"
                                format="success"
                                disabled={issues.size === 0 && !feedback && !rating}
                            >
                                Submit
                            </SubmitButtonStyled>
                        </SectionStyled>
                    </>
                )}
            </InnerStyled>
        </FeedbackModuleStyled>
    );
};

const ThankYouGifStyled = styled.img`
    width: 100%;
    max-width: ${rem(300)};
    max-height: ${rem(168)};
    height: auto;
    border-radius: ${rem(8)};
    overflow: hidden;
`;

const TextAreaStyled = styled(TextArea)`
    height: ${rem(72)};
    min-width: ${rem(280)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        min-width: ${rem(300)};
    }
`;

const OptionStyled = styled(CheckSelectInput)`
    color: ${({ theme }) => theme.palette.LightGrey1};
    white-space: nowrap;
    margin-right: ${rem(20)};

    & + & {
        margin-top: ${rem(12)};
    }
`;

const SectionStyled = styled.div`
    & + & {
        margin-top: ${rem(20)};
    }
`;

const CircledIconStyles = css`
    height: ${rem(32)};
    width: ${rem(32)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        height: ${rem(18)};
        width: ${rem(18)};
    }
`;

const SubmitButtonStyled = styled(FilledButton)`
    width: ${rem(140)};
    margin-top: ${rem(12)};
    width: 100%;

    > * {
        opacity: 1;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-right: ${rem(24)};
    }
`;

const HeadingStyled = styled.h3`
    font-size: ${rem(13)};
    color: ${({ theme }) => theme.palette.White};
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: ${rem(12)};
`;

const FiveStarsStyled = styled(FiveStars)``;

const InnerStyled = styled.form`
    flex: 1;
    box-sizing: border-box;
`;

const PrimarySubheadingStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13};
    margin-bottom: ${rem(20)};
`;

const PrimaryHeadingStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading20};
    margin-bottom: ${rem(12)};
`;

const FeedbackModuleStyled = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    pointer-events: auto;
`;

export default forwardRef(FeedbackModule);
