import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface DropdownListItem {
    className?: string;
    mobile: boolean;
    inactive?: boolean;
    disabled?: boolean;
    onClick: () => void;
    children: React.ReactNode | [React.ReactNode, React.ReactNode];
}

const DropdownListItem: React.FunctionComponent<DropdownListItem> = (props) => {
    const { className, mobile, children, inactive = false, disabled = false, onClick } = props;

    return (
        <DropdownListItemStyled
            className={className}
            inactive={inactive}
            disabled={disabled}
            mobile={mobile}
            role="button"
            onClick={onClick}
        >
            {children}
        </DropdownListItemStyled>
    );
};

const DropdownListItemStyled = styled.li<{ inactive: boolean; mobile: boolean; disabled: boolean }>`
    ${({ theme, mobile }) => (mobile ? theme.typography.Heading16 : theme.typography.Heading13)};
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    letter-spacing: unset;
    height: ${({ mobile }) => (mobile ? rem(40) : rem(28))};
    padding: 0 ${({ mobile }) => (mobile ? 0 : rem(6))};
    transition: background-color 50ms ease, color 50ms ease;
    background-color: transparent;
    color: ${({ inactive, theme }) => (inactive ? theme.palette.LightGrey1 : theme.palette.White)};
    cursor: pointer;
    border-radius: ${rem(4)};

    & + & {
        margin-top: ${rem(4)};
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  pointer-events: none;
                  opacity: 0.5;
              `
            : css`
                  @media (hover: hover) {
                      &:hover {
                          background-color: ${({ theme }) => theme.palette.DarkGrey2};
                          color: ${({ theme }) => theme.palette.White};
                      }
                  }
              `}

    svg {
        height: ${({ mobile }) => rem(mobile ? 20 : 16)};
        width: ${({ mobile }) => rem(mobile ? 20 : 16)};
    }
`;

export default DropdownListItem;
