import React from 'react';
import styled from 'styled-components';
import BackIcon from '../../assets/svg/arrow.svg';
import FilledButton from './FilledButton';

interface BackButton {
    className?: string;
    transparent?: boolean;
    tabIndex?: number;
    size?: FilledButton['size'];
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BackButton: React.FunctionComponent<BackButton> = (props) => {
    const { className, transparent = false, tabIndex, size = 'md', onClick, children } = props;

    return (
        <BackButtonStyled
            className={className}
            tabIndex={tabIndex}
            onClick={onClick}
            type="button"
            size={size}
            icon
            transparent={transparent}
        >
            <BackIcon />
            {children}
        </BackButtonStyled>
    );
};

const BackButtonStyled = styled(FilledButton)`
    position: relative;
`;

export default BackButton;
