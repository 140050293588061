import '../styles/global.css';
import '@reach/dialog/styles.css';
import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { wrapper } from '../state/store';
import { Breakpoints, Colors, Palette, Typography, Common } from 'packages/common/base/styles';
import { AppComponent, ModalType } from 'typescript/typings';
import loadPolyfills from 'lib/polyfills';
import usePageView from '../hooks/usePageView';
import useAuthRedirect from '../hooks/useAuthRedirect';
import dynamic from 'next/dynamic';
import useFirstUserInteraction from '../hooks/useFirstUserInteraction';
import { ignoreWhitelistedErrors } from 'packages/common/base/utils/errors';
import AuthProvider from '../context/auth';
import AppLoader from '../components/AppLoader';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { useRouter } from 'next/router';
import { hideAccountPanel, setNavigating } from '../state/app';
import redirects from '../routing/redirects';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import { track } from '../state/app';

const DynamicAuth = dynamic(() => import('../components/Auth'), { ssr: false });
const DynamicAccountPanel = dynamic(() => import('../components/accountPanel'), { ssr: false });

const theme = {
    typography: Typography,
    breakpoints: Breakpoints,
    common: Common,
    colors: Colors,
    palette: Palette,
};

interface IApp extends AppProps {
    Component: AppComponent<any>;
    pageProps: any;
}

const PlaybackApp: React.FunctionComponent<IApp> = ({ Component, pageProps, ...rest }) => {
    const Layout = Component.Layout || React.Fragment;
    const router = useRouter();

    const dispatch = useAppDispatch();
    const loaderVisible = useAppSelector(
        (state) => state.app.loaderVisible || state.app.navigating
    );
    const modalType = useAppSelector((state) => state.app.modal?.type);
    const loggedIn = useAppSelector((state) => !!state.user.id);
    const authRedirect = redirects[router.pathname];

    const [showAuth, setShowAuth] = useState(false);

    usePageView();
    useAuthRedirect();
    useFirstUserInteraction();

    useEffect(() => {
        loadPolyfills();
        ignoreWhitelistedErrors();

        const handleRouteChangeStart = () => {
            dispatch(hideAccountPanel());
        };

        const handleRouteChangeComplete = () => {
            dispatch(setNavigating(false));
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [dispatch, router]);

    useEffect(() => {
        // Don't unmount auth when closed
        if (modalType === ModalType.Login || modalType === ModalType.Signup) {
            setShowAuth(true);
        }
    }, [modalType]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=1, viewport-fit=cover"
                />
                <meta
                    name="facebook-domain-verification"
                    content="3zw4n8y0j5vammwk0sr30vefetni9l"
                />
            </Head>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={async (error) => {
                    dispatch(
                        track({
                            event: 'Error Caught at Boundary',
                            errorMessage: error.message,
                            errorName: error.name,
                            errorStack: error.stack,
                        })
                    );

                    const Sentry = await import('@sentry/nextjs');
                    Sentry.captureException(error, {
                        tags: {
                            appContext: 'errorBoundary',
                        },
                    });
                }}
            >
                <AuthProvider>
                    <Layout>
                        <Component {...pageProps} {...rest} />
                        {showAuth && (
                            <DynamicAuth
                                redirectOnLogin={!!authRedirect?.to && authRedirect?.loggedIn}
                            />
                        )}
                        {loggedIn && <DynamicAccountPanel />}
                        <AppLoader
                            visible={loaderVisible}
                            transitionDuration={200}
                            transitionDelay={0}
                        />
                    </Layout>
                </AuthProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default wrapper.withRedux(PlaybackApp);
