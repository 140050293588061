import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { TVAuthSubscription } from 'packages/common/api-client/types';
import { platformsToNames } from 'packages/common/tv-auth/platforms';
import TextInput from 'packages/common/base/components/TextInput';
import SearchIcon from 'packages/common/assets/svg/search.svg';
import { comingSoon, PlatformAuthConfig, platforms } from 'constants/tvAuth';
import ConnectedSubscription from '../../ConnectedSubscription';

interface SubscriptionList {
    className?: string;
    subscriptions: TVAuthSubscription[];
    onSelect: (platform: PlatformAuthConfig) => void;
    onDisconnect: (subscription: TVAuthSubscription) => void;
}

const SubscriptionList: React.FunctionComponent<SubscriptionList> = (props) => {
    const { className, subscriptions, onSelect, onDisconnect } = props;

    const [searchQuery, setSearchQuery] = useState('');

    const unsubbedPlatformed = platforms.filter(
        (platform) => !subscriptions.some((sub) => sub.platform === platform.platform)
    );

    const getFilteredPlatforms = () => {
        return platforms
            .filter((platform) =>
                platformsToNames[platform.platform]
                    .toLowerCase()
                    .trim()
                    .includes(searchQuery.toLowerCase().trim())
            )
            .sort((platform) =>
                subscriptions.some((sub) => sub.platform === platform.platform) ? 1 : -1
            );
    };

    const getFilteredComingSoon = () => {
        return comingSoon.filter((platform) =>
            platform.name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim())
        );
    };

    const filteredPlatforms = searchQuery ? getFilteredPlatforms() : unsubbedPlatformed;
    const filteredComingSoon = searchQuery ? getFilteredComingSoon() : comingSoon;

    return (
        <SubscriptionPickerStyled className={className}>
            <SearchInputContainer>
                <SearchInputStyled
                    inputSize="sm"
                    name="Search services"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                    spellCheck={false}
                    autoComplete="false"
                    placeholder="Search services"
                    type="text"
                />
                <SearchIconStyled />
            </SearchInputContainer>
            {filteredPlatforms.length > 0 && (
                <SubscriptionListStyled>
                    {filteredPlatforms.map((platform) => {
                        const connected = subscriptions.find(
                            (sub) => sub.platform === platform.platform
                        );

                        return connected ? (
                            <ConnectedSubscription
                                key={platform.platform}
                                subscription={connected}
                                onDisconnect={() => {
                                    onDisconnect(connected);
                                }}
                            />
                        ) : (
                            <SubscriptionStyled
                                key={platform.platform}
                                onClick={() => {
                                    onSelect(platform);
                                }}
                                title={platformsToNames[platform.platform]}
                            >
                                <SubscriptionImageStyled
                                    src={platform.icon}
                                    alt={platformsToNames[platform.platform]}
                                />
                            </SubscriptionStyled>
                        );
                    })}
                </SubscriptionListStyled>
            )}
            {filteredComingSoon.length > 0 && (
                <>
                    <ComingSoonHeaderStyled>COMING SOON</ComingSoonHeaderStyled>
                    {filteredComingSoon.map((platform) => (
                        <SubscriptionStyled key={platform.name} disabled title={platform.name}>
                            <SubscriptionImageStyled
                                src={platform.icon}
                                alt={platform.name}
                                dimmed
                            />
                        </SubscriptionStyled>
                    ))}
                </>
            )}
        </SubscriptionPickerStyled>
    );
};

const ComingSoonHeaderStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading13}
    color: ${({ theme }) => theme.palette.LightGrey1};
    margin-bottom: ${rem(12)};
`;

const SubscriptionImageStyled = styled.img<{ dimmed?: boolean }>`
    height: ${rem(80)};
    width: ${rem(142)};

    ${({ dimmed }) =>
        dimmed &&
        css`
            opacity: 0.5;
        `}
`;

const SubscriptionStyled = styled.button<{ connected?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${rem(4)};
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    box-shadow: 0 0 0 ${rem(1)} transparent;
    transition: box-shadow 50ms ease, background-color 50ms ease;
    position: relative;
    user-select: none;

    & + & {
        margin-top: ${rem(12)};
    }

    &:disabled {
        cursor: default;
    }

    @media (hover: hover) {
        &:hover:not(:disabled) {
            box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.palette.White};
            background-color: ${({ theme }) => theme.palette.DarkGrey1};
        }
    }

    ${({ connected, theme }) =>
        connected &&
        css`
            box-shadow: inset 0 0 0 ${rem(2)} ${theme.palette.Green};
            pointer-events: none;
            cursor: default;
        `}
`;

const SearchIconStyled = styled(SearchIcon)`
    height: ${rem(20)};
    width: ${rem(20)};
    position: absolute;
    left: ${rem(12)};
    top: 50%;
    transform: translateY(-50%);
    transition: fill 50ms ease;
    color: ${({ theme }) => theme.palette.LightGrey1};
`;

const SearchInputStyled = styled(TextInput)`
    input {
        padding-left: ${rem(40)};
    }
`;

const SearchInputContainer = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: ${rem(24)};
`;

const SubscriptionListStyled = styled.div`
    margin-bottom: ${rem(40)};

    > * + * {
        margin-top: ${rem(12)};
    }
`;

const SubscriptionPickerStyled = styled.div`
    align-self: stretch;
    padding-bottom: ${rem(20)};
`;

export default SubscriptionList;
