import * as WS from 'isomorphic-ws';
import base64 from 'base-64';
import nanoid from '../base/utils/nanoid';
import { AddonTypes, Platforms, SubscriptionTypes } from './platforms';
import { WebSocket } from './websocket';

export enum SignalType {
    Heartbeat = 0,
    Init = 1,
    Auth = 2,
    AuthSuccess = 3,
    AuthFail = 4,
    AuthPortal = 5,
    AuthInvalid = 6,
    AuthUsernameInvalid = 7,
    AuthPasswordInvalid = 8,
    AuthPortalTimeout = 9,
    Subscription = 10,
    Unauthorized = 11,
    SubscriptionFail = 12,
}

export interface BaseSignal {
    type: SignalType;
}

export interface InitSignal extends BaseSignal {
    portalID: string;
}

export interface AuthSignal extends BaseSignal {
    platform: Platforms;
    userID: string;
    username: string;
    password: string;
}

export interface AuthSuccessSignal extends BaseSignal {
    platform: Platforms;
    screenshot?: string | Buffer;
}
export interface AuthFailSignal extends BaseSignal {
    platform: Platforms;
    message?: string;
    screenshot?: string | Buffer;
}
export interface AuthPortalSignal extends BaseSignal {
    platform: Platforms;
    portalID: string;
}

export interface AuthInvalidSignal extends BaseSignal {
    platform: Platforms;
}

export interface AuthUsernameInvalidSignal extends BaseSignal {
    platform: Platforms;
    username: string;
}

export interface AuthPasswordInvalidSignal extends BaseSignal {
    platform: Platforms;
}

export interface AuthPortalTimeoutSignal extends BaseSignal {
    platform: Platforms;
}

export interface UnauthorizedSignal extends BaseSignal {}

export interface SubscriptionSignal extends BaseSignal {
    platform: Platforms;
    subType: SubscriptionTypes;
    addons: AddonTypes[];
    location: string;
    username: string;
}

export interface SubscriptionFailSignal extends BaseSignal {
    platform: Platforms;
}

export interface IClientHandler {
    onopen: (reconnect: boolean) => void;
    ondisconnect: (retrying: boolean) => void;
    onInit: (signal: InitSignal) => void;
    onAuthSuccess: (signal: AuthSuccessSignal) => void;
    onAuthPortal: (signal: AuthPortalSignal) => void;
    onAuthFail: (signal: AuthFailSignal) => void;
    onAuthUsernameInvalid: (signal: AuthUsernameInvalidSignal) => void;
    onAuthPasswordInvalid: (signal: AuthPasswordInvalidSignal) => void;
    onAuthInvalid: (signal: AuthPasswordInvalidSignal) => void;
    onUnauthorized: (signal: UnauthorizedSignal) => void;
    onSubscription: (signal: SubscriptionSignal) => void;
}

export interface IClientParams {
    wsURI: string;
    accessToken?: string;
    handler: IClientHandler;
    viewport: {
        width: number;
        height: number;
        // deviceScaleFactor: number;
        // isMobile: boolean;
        // hasTouch: boolean;
        // isLandscape: boolean;
    };
}

export interface IConnectionParams {
    id: string;
    viewport: {
        width: number;
        height: number;
        // deviceScaleFactor: number;
        // isMobile: boolean;
        // hasTouch: boolean;
        // isLandscape: boolean;
    };
}

export class Client {
    private _id: string;
    protected _ws: WebSocket;
    private _handler: IClientHandler;

    constructor(config: IClientParams) {
        const { wsURI, handler } = config;

        this._id = nanoid();

        this._handler = handler;

        const params: IConnectionParams = {
            id: this._id,
            viewport: config.viewport,
        };

        const jsonParams = JSON.stringify(params);
        const b64EncodedParams = base64.encode(jsonParams);
        const wsQS = `?params=${b64EncodedParams}`;
        let accessToken = '';
        if (config.accessToken) {
            accessToken = config.accessToken;
        }
        this._ws = new WebSocket(`${wsURI}${wsQS}`, accessToken);

        this._addSignalListeners();
    }

    private _addSignalListeners() {
        this._ws.onopen = this._handleOpen.bind(this);
        this._ws.ondisconnect = this._handleDisconnect.bind(this);
        this._ws.addMessageListener(this._handleMessage.bind(this));
    }

    private _handleMessage(event: WS.MessageEvent) {
        const s: BaseSignal = JSON.parse(<string>event.data);
        switch (s.type) {
            case SignalType.Init:
                this._handler.onInit(s as InitSignal);
                break;
            case SignalType.AuthSuccess:
                this._handler.onAuthSuccess(s as AuthSuccessSignal);
                break;
            case SignalType.AuthPortal:
                this._handler.onAuthPortal(s as AuthPortalSignal);
                break;
            case SignalType.AuthFail:
                this._handler.onAuthFail(s as AuthFailSignal);
                break;
            case SignalType.AuthUsernameInvalid:
                this._handler.onAuthUsernameInvalid(s as AuthUsernameInvalidSignal);
                break;
            case SignalType.AuthPasswordInvalid:
                this._handler.onAuthPasswordInvalid(s as AuthPasswordInvalidSignal);
                break;
            case SignalType.AuthInvalid:
                this._handler.onAuthInvalid(s as AuthInvalidSignal);
                break;
            case SignalType.Unauthorized:
                this._handler.onUnauthorized(s as UnauthorizedSignal);
                break;
            case SignalType.Subscription:
                this._handler.onSubscription(s as SubscriptionSignal);
                break;
            default:
        }
    }

    private _handleOpen(reconnect: boolean) {
        this._handler.onopen(reconnect);
    }

    private _handleDisconnect(retrying: boolean) {
        this._handler.ondisconnect(retrying);
    }

    auth(userID: string, username: string, password: string, platform: Platforms) {
        const s: AuthSignal = {
            type: SignalType.Auth,
            userID,
            platform,
            username,
            password,
        };
        this._ws.send(s);
    }

    async close() {
        this._ws.close();
    }
}
