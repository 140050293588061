import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { TVAuthSubscription } from 'packages/common/api-client/types';
import FilledButton, { FilledButtonStyles } from 'packages/common/base/components/FilledButton';
import EmptyState from 'packages/common/base/components/EmptyState';
import PlusIcon from 'packages/common/assets/svg/plus.svg';
import CheckIcon from 'packages/common/assets/svg/check.svg';
import TvIcon from 'packages/common/assets/svg/tv.svg';
import Column from 'packages/common/base/styles/components/Column';
import ConnectedSubscription from '../../ConnectedSubscription';
import StatusNotification from '../../StatusNotification';
import { platformsToNames, Platforms } from 'packages/common/tv-auth/platforms';

interface SubscriptionList {
    className?: string;
    subscriptions: TVAuthSubscription[];
    onClickAdd: () => void;
    onDisconnect: (subscription: TVAuthSubscription) => void;
}

const SubscriptionList: React.FunctionComponent<SubscriptionList> = (props) => {
    const { className, subscriptions, onClickAdd, onDisconnect } = props;

    return (
        <SubscriptionListStyled className={className}>
            <AddSubscriptionButtonStyled onClick={onClickAdd}>
                <AddSubscriptionIconStyled size="md" format="neutral_light" icon>
                    <PlusIcon />
                </AddSubscriptionIconStyled>
                <AddSubscriptionDetailsStyled>
                    <AddSubscriptionHeadingStyled>
                        {subscriptions.length === 0
                            ? 'Connect a subscription'
                            : 'Connect another subscription'}
                    </AddSubscriptionHeadingStyled>
                    <AddSubscriptionDescriptionStyled>
                        You’ll have access to any content included in your connected services.
                    </AddSubscriptionDescriptionStyled>
                </AddSubscriptionDetailsStyled>
            </AddSubscriptionButtonStyled>
            {subscriptions.length === 0 ? (
                <EmptyStateStyled
                    icon={<TvIcon />}
                    title="You haven’t connected any subscriptions"
                    description="Connect your TV subscriptions to verify your access to streams in your Playback rooms."
                />
            ) : (
                <>
                    <AccountsHeaderStyled>YOUR SUBSCRIPTIONS</AccountsHeaderStyled>
                    {subscriptions.map((subscription) => (
                        <ConnectedSubscriptionStyled
                            key={subscription.id}
                            subscription={subscription}
                            onDisconnect={() => {
                                onDisconnect(subscription);
                            }}
                        />
                    ))}
                </>
            )}
        </SubscriptionListStyled>
    );
};

const SuccessNotificationStyled = styled(StatusNotification)`
    margin-bottom: ${rem(24)};
`;

const ConnectedSubscriptionStyled = styled(ConnectedSubscription)`
    & + & {
        margin-top: ${rem(20)};
    }
`;

const AccountsHeaderStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading13}
    ${({ theme }) => theme.typography.FontBold}
    color: ${({ theme }) => theme.palette.White};
    margin-bottom: ${rem(12)};
    margin-top: ${rem(28)};
`;

const EmptyStateStyled = styled(EmptyState)`
    flex: 1;
`;

const AddSubscriptionDescriptionStyled = styled.div`
    ${({ theme }) => theme.typography.Paragraph13}
`;

const AddSubscriptionHeadingStyled = styled.div`
    ${({ theme }) => theme.typography.Heading16}
    margin-bottom: ${rem(5)};
`;

const AddSubscriptionDetailsStyled = styled.div`
    margin-left: ${rem(16)};
    text-align: left;
`;

const AddSubscriptionIconStyled = styled.div<FilledButton>`
    ${FilledButtonStyles};
    margin-top: ${rem(4)};
`;

const AddSubscriptionButtonStyled = styled.button`
    background-color: ${({ theme }) => theme.palette.MedGrey4};
    border-radius: ${rem(8)};
    padding: ${rem(16)};
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-shadow: 0 0 0 ${rem(1)} transparent;
    transition: box-shadow 50ms ease;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 0 0 ${rem(1)} ${({ theme }) => theme.palette.White};

            ${AddSubscriptionIconStyled} {
                background-color: ${({ theme }) => theme.palette.MedGrey1};
            }
        }
    }
`;

const SubscriptionListStyled = styled(Column)`
    align-self: stretch;
    flex: 1;
`;

export default SubscriptionList;
