import { NextPage } from 'next';
import { ChannelResponse, ChannelState } from 'stream-chat';

export interface RedirectOptions {
    permissions?: PagePermissions[];
    loggedIn: boolean;
    to: string;
}

export enum PagePermissions {
    Staff,
}

export type AppComponent<T> = NextPage<T> & {
    Layout?: React.FunctionComponent<any>;
    Mobile?: boolean;
};

export type Path = string | { href: string; as: string };

export type ILegalDocument = {
    name: string;
    shortName: string;
    url: string;
    slug: string;
};

export enum AppDownloadFileType {
    MacDmg = 'darwin-x64-dmg',
    MacZip = 'darwin-x64-zip',
    MacInstallerZip = 'darwin-installer-zip',
    WindowsExe = 'win32-x64-exe',
    WindowsZip = 'win32-x64-zip',
}

export enum ModalType {
    StreamTroubleshooting,
    Login,
    Signup,
    CreateRoom,
    DupeConnectionConfirmation,
    RoomSettings,
    RoomDisconnected,
    RoomArchived,
    DuplicateUser,
    BanUser,
    Feedback,
    SubscribeError,
    DownloadApp,
    StreamingServiceSurvey,
    PermissiveTvAuth,
    MobileWebWarning,
    IphoneAppPrompt,
}

export interface Modal {
    type: ModalType;
    payload?: any;
}

export enum OS {
    Mac = 'macOS',
    Windows = 'Windows',
}

export enum ReferralContext {
    DownloadLinkInRoom,
}

export enum AdCampaignSource {
    Facebook = 'fb',
}

export const REFERRAL_CONTEXT_NAME = {
    [ReferralContext.DownloadLinkInRoom]: 'Download Link (In Room)',
};

export enum TrackingContext {
    Nav = 'Nav',
    RoomDropdown = 'Room Dropdown',
    AccountDropdown = 'Account Dropdown',
    LoginForm = 'Login Form',
    SignupForm = 'Signup Form',
    RoomLobby = 'Room Lobby',
    RoomNav = 'Room Nav',
    RoomControls = 'Room Controls',
    StreamPrompt = 'Room Stream Prompt',
    VideoChat = 'Video Chat',
    VideoOverlay = 'Video Overlay',
    MobileTextChat = 'Text Chat (Mobile)',
    MobileChatter = 'Voice Chat (Mobile)',
    MobileStage = 'Stage (Mobile)',
    DownloadAppModal = 'Download App Modal',
}

export enum Cookie {
    LoggedIn = 'loggedIn',
}

export type SupportChat = {
    data: ChannelResponse;
    messages: ChannelState['messages'];
    support: {
        lastOpened?: number;
        resolved?: number;
        resolvedByUser?: number;
    };
};
