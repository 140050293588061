import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ToggleSwitch from './ToggleSwitch';

interface LabeledToggleSwitch extends Pick<ToggleSwitch, 'onChange' | 'onClick' | 'disabled'> {
    className?: string;
    id: string;
    enabled: boolean;
    title: string;
    subtitle?: string;
    reverse?: boolean;
}

const LabeledToggleSwitch: React.FunctionComponent<LabeledToggleSwitch> = (props) => {
    const {
        className,
        id,
        title,
        subtitle,
        enabled,
        disabled = false,
        reverse = false,
        onChange,
    } = props;

    return (
        <LabeledToggleSwitchStyled
            className={className}
            disabled={disabled}
            htmlFor={id}
            reverse={reverse}
        >
            <ToggleSwitch id={id} toggled={enabled} disabled={disabled} onChange={onChange} />
            <TextStyled reverse={reverse}>
                <TitleStyled>{title}</TitleStyled>
                {subtitle && <DescriptionStyled>{subtitle}</DescriptionStyled>}
            </TextStyled>
        </LabeledToggleSwitchStyled>
    );
};

const TitleStyled = styled.span`
    font-size: inherit;
    color: ${({ theme }) => theme.palette.White};
    transition: color 150ms ease;
    margin: 0;
`;

const DescriptionStyled = styled.p`
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.palette.LightGrey1};
    margin: ${rem(4)} 0 0 0;
`;

const TextStyled = styled.div<{ reverse: boolean }>`
    margin-left: ${rem(8)};
    display: flex;
    flex-direction: column;

    ${({ reverse }) =>
        reverse &&
        css`
            margin-left: 0;
            margin-right: ${rem(8)};
        `}
`;

const LabeledToggleSwitchStyled = styled.label<{ disabled: boolean; reverse: boolean }>`
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: flex-start;
    align-items: center;
    transition: opacity 100ms ease;
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    user-select: none;
    flex-shrink: 0;
    font-size: ${rem(13)};
    cursor: pointer;
`;

export default LabeledToggleSwitch;
