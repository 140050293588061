import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import {
    AuthFailSignal,
    AuthPortalSignal,
    AuthSuccessSignal,
    AuthUsernameInvalidSignal,
    AuthPasswordInvalidSignal,
    InitSignal,
    Client,
    IClientParams,
    IClientHandler,
    AuthInvalidSignal,
    UnauthorizedSignal,
    SubscriptionSignal,
} from 'packages/common/tv-auth/client';
import { useAppSelector } from 'state/hooks';
import Runner from 'puppeteer-portal/runner';
import {
    Platforms,
    platformsToNames,
    vpnRequiredPlatforms,
    SubscriptionTypes,
    AddonTypes,
} from 'packages/common/tv-auth/platforms';
import Spinner from 'packages/common/base/components/Spinner';
import { PlatformAuthConfig, WS_HOST } from 'constants/tvAuth';
import { Palette } from 'packages/common/base/styles';
import TextInput from 'packages/common/base/components/TextInput';
import FilledButton from 'packages/common/base/components/FilledButton';
import Column from 'packages/common/base/styles/components/Column';
import useAuth from 'hooks/useAuth';
import Centered from 'packages/common/base/styles/components/Centered';
import LockIcon from 'packages/common/assets/svg/lock.svg';
import AlertIcon from 'packages/common/assets/svg/alert.svg';
import CheckIcon from 'packages/common/assets/svg/check.svg';
import StatusNotification from '../../StatusNotification';

export enum SubscriptionAuthStep {
    Login,
    Connecting,
    Portal,
    Verifying,
    Verified,
    Blocked,
}

const PORTAL_MAX_HEIGHT = 640;

interface SubscriptionAuth {
    className?: string;
    client: MutableRefObject<Client>;
    platform: PlatformAuthConfig;
    step: SubscriptionAuthStep;
    alwaysShowPortal: boolean;
    useLocal: boolean;
    onConnectStart: () => void;
    onError: (message: string) => void;
    onShowPortal: () => void;
    onHidePortal: () => void;
    onConnected: () => void;
    onSubscribed: (subType: SubscriptionTypes, addons: AddonTypes[], location: string) => void;
    onExit: () => void;
    onAddMore: () => void;
}

const SubscriptionAuth: React.FC<SubscriptionAuth> = (props) => {
    const {
        className,
        client,
        platform,
        step,
        alwaysShowPortal,
        useLocal,
        onConnectStart,
        onError,
        onConnected,
        onSubscribed,
        onShowPortal,
        onHidePortal,
        onExit,
        onAddMore,
    } = props;

    const { logout } = useAuth();

    const runnerRef = useRef<Runner>(null);
    const canvasRef = useRef<HTMLCanvasElement>();
    const container = useRef<HTMLDivElement>(null);
    const portalSize = useRef<{ height: number; width: number }>({ height: 640, width: 360 });

    const [wsConnected, setWsConnected] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [error, setErrorMessage] = useState<string>('');
    const [portalVisible, setPortalVisible] = useState(false);

    const userID = useAppSelector((state) => state.user.id);
    const accessToken = useAppSelector((state) => state.user.accessToken);
    const mobile = useAppSelector((state) => state.app.mobile);

    const platformName = platformsToNames[platform.platform];
    const showPortal = alwaysShowPortal || step === SubscriptionAuthStep.Portal;

    const desktopOnly = platform.platform === Platforms.YouTubeTV && mobile;

    const setupPortalRunner = (portalID: string, platform: Platforms) => {
        if (runnerRef.current) {
            runnerRef.current.close();
        }

        runnerRef.current = new Runner({
            portalID,
            canvas: canvasRef.current,
            onClose: () => {
                console.log('runner close');
            },
            remote: !useLocal,
            useVPN: vpnRequiredPlatforms.includes(platform),
        });
    };

    const setupClient = async (initHandler: (c: Client) => void = () => undefined) => {
        if (client.current) {
            await client.current.close();
        }

        const handler: IClientHandler = {
            onopen: (_reconnect: boolean) => {},
            ondisconnect: (retrying: boolean) => {
                console.log('ondisconnect', retrying);
                setWsConnected(false);
            },
            onInit: (signal: InitSignal) => {
                initHandler(client.current);
                setWsConnected(true);

                if (alwaysShowPortal) {
                    setupPortalRunner(signal.portalID, platform.platform);
                }
            },
            onAuthSuccess: (signal: AuthSuccessSignal) => {
                console.log('auth success', signal);
                onHidePortal();
                onConnected();
                setErrorMessage(null);
            },
            onSubscription: (signal: SubscriptionSignal) => {
                console.log('subscription signal', signal);
                onSubscribed(signal.subType, signal.addons, signal.location);
            },
            onAuthPortal: (signal: AuthPortalSignal) => {
                console.log('auth portal', signal.portalID);
                onShowPortal();

                if (!alwaysShowPortal) {
                    setupPortalRunner(signal.portalID, signal.platform);
                }
            },
            onAuthFail: (signal: AuthFailSignal) => {
                console.log('auth fail', signal);
                setErrorMessage(
                    'Hmm, looks like something went wrong. Please try submitting again.'
                );
                onError(signal.message);
            },
            onAuthUsernameInvalid: (signal: AuthUsernameInvalidSignal) => {
                console.log('auth username invalid', signal);
                setUsernameError(true);
                setErrorMessage(
                    `Hmm, that ${platform.usernameCopy.toLowerCase()} wasn’t recognized.`
                );
                onError('Invalid username');
            },
            onAuthPasswordInvalid: (signal: AuthPasswordInvalidSignal) => {
                console.log('auth password invalid', signal);
                setPasswordError(true);
                setPassword('');
                setErrorMessage('Looks like you entered an incorrect password.');
                onError('Incorrect password');
            },
            onAuthInvalid: (signal: AuthInvalidSignal) => {
                console.log('auth invalid', signal);
                setErrorMessage(
                    `Hmm, those credentials weren’t recognized. Please re-enter your ${platform.usernameType.toLowerCase()} and password and try again.`
                );
                onError('Invalid auth');
            },
            onUnauthorized: (signal: UnauthorizedSignal) => {
                console.log('api unauthorized', signal);
                logout();
                onExit();
            },
        };

        const useVPN = vpnRequiredPlatforms.includes(platform.platform);
        let wsURI = `${WS_HOST}${useVPN ? '/vpn' : ''}/ws`;
        if (useLocal) {
            wsURI = 'ws://localhost:8072';
        }

        const params: IClientParams = {
            wsURI,
            handler,
            accessToken,
            viewport: portalSize.current,
        };

        client.current = new Client(params);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let valid = true;

        if (!username) {
            valid = false;
            setUsernameError(true);
        }

        if (!password) {
            valid = false;
            setPasswordError(true);
        }

        if (valid && client.current) {
            client.current.auth(userID, username, password, platform.platform);
            onConnectStart();
            setErrorMessage(null);
        }
    };

    useEffect(() => {
        if (step === SubscriptionAuthStep.Login && !desktopOnly) {
            const { height, width } = container.current.getBoundingClientRect();
            portalSize.current = {
                height: Math.min(Math.round(height), PORTAL_MAX_HEIGHT),
                width: Math.round(width),
            };

            setupClient();
            runnerRef?.current?.close();
            runnerRef.current = null;
        }
    }, [step, desktopOnly]);

    useEffect(() => {
        if (showPortal) {
            const timeout = setTimeout(() => {
                setPortalVisible(true);
            }, 1000);

            return () => {
                setPortalVisible(false);
                clearTimeout(timeout);
            };
        }
    }, [showPortal]);

    useEffect(() => {
        return () => {
            runnerRef.current?.close();
            runnerRef.current = null;
        };
    }, []);

    const renderPortal = () => (
        <PortalContainerStyled style={{ ...portalSize.current }} visible={portalVisible}>
            <canvas
                width={portalSize.current.width}
                height={portalSize.current.height}
                ref={canvasRef}
            />
        </PortalContainerStyled>
    );

    if (!alwaysShowPortal && step === SubscriptionAuthStep.Portal) {
        return renderPortal();
    }

    return (
        <TVAuthStyled className={className} alignItems="stretch" ref={container}>
            <PlatformInfoStyled alignItems="center">
                <PlatformIconContainerStyled
                    mobile={mobile}
                    succeeded={step === SubscriptionAuthStep.Verified}
                    failed={step === SubscriptionAuthStep.Blocked}
                >
                    <PlatformIconStyled src={platform.icon} alt={platformName} />
                    {(step === SubscriptionAuthStep.Connecting ||
                        step === SubscriptionAuthStep.Verifying) && (
                        <SpinnerStyled
                            size="sm"
                            color={
                                step === SubscriptionAuthStep.Connecting
                                    ? Palette.Yellow
                                    : Palette.Green
                            }
                        />
                    )}
                    {(step === SubscriptionAuthStep.Blocked ||
                        step === SubscriptionAuthStep.Verified) && (
                        <StatusIconStyled
                            succeeded={step === SubscriptionAuthStep.Verified}
                            failed={step === SubscriptionAuthStep.Blocked}
                        >
                            {step === SubscriptionAuthStep.Verified ? <CheckIcon /> : <AlertIcon />}
                        </StatusIconStyled>
                    )}
                </PlatformIconContainerStyled>
                <Column alignItems="center">
                    <PlatformNameStyled>{platformName}</PlatformNameStyled>
                    <PlatformUrlStyled
                        href={platform.loginUrl}
                        target="_blank"
                        title={`Open ${platformName} login page`}
                    >
                        {platform.displayUrl}
                    </PlatformUrlStyled>
                </Column>
            </PlatformInfoStyled>
            <AuthContainerStyled>
                {desktopOnly && (
                    <StatusNotificationStyled
                        format="warning"
                        icon={<AlertIcon />}
                        title="PLEASE LOG IN ON DESKTOP"
                        body={`At the moment, ${platformName} is only available on desktop. Mobile support is coming soon!`}
                    />
                )}
                {error && <ErrorMessageStyled>{error}</ErrorMessageStyled>}
                {step === SubscriptionAuthStep.Login && (
                    <AuthFormStyled noValidate onSubmit={handleSubmit}>
                        <TextInputStyled
                            type={platform.usernameType}
                            autoFocus={!passwordError || usernameError}
                            autoComplete="off"
                            placeholder={platform.usernamePlaceholder || platform.usernameCopy}
                            label={platform.usernameCopy}
                            value={username}
                            hasError={usernameError}
                            disabled={desktopOnly}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                setUsernameError(false);
                            }}
                        />
                        <TextInputStyled
                            type="password"
                            autoFocus={passwordError}
                            autoComplete="off"
                            placeholder="Password"
                            label="Password"
                            value={password}
                            hasError={passwordError}
                            disabled={desktopOnly}
                            onChange={(e) => {
                                setPasswordError(false);
                                setPassword(e.target.value);
                            }}
                        />
                        {platform.passwordResetUrl && !desktopOnly && (
                            <ForgotPasswordStyled href={platform.passwordResetUrl} target="_blank">
                                Forgot your password?
                            </ForgotPasswordStyled>
                        )}
                        <FilledButton
                            format="neutral_light"
                            size="lg"
                            type="submit"
                            style={{ marginTop: rem(32) }}
                            disabled={
                                desktopOnly ||
                                !wsConnected ||
                                !username ||
                                !password ||
                                usernameError ||
                                passwordError
                            }
                        >
                            Connect
                        </FilledButton>
                        <PrivacySubtitleStyled>
                            <PrivateIconContainerStyled>
                                <LockIcon />
                            </PrivateIconContainerStyled>
                            {`Credentials are securely sent to ${platformName}, and your password is never stored by Playback.`}
                        </PrivacySubtitleStyled>
                    </AuthFormStyled>
                )}
                {step === SubscriptionAuthStep.Connecting && (
                    <ConnectingStateContainerStyled alignItems="center">
                        <ConnectingStatusStyled>{`Connecting to ${platformName}`}</ConnectingStatusStyled>
                        <ConnectingDetailStyled>
                            We’re securely sending your credentials and logging you in.
                        </ConnectingDetailStyled>
                    </ConnectingStateContainerStyled>
                )}
                {step === SubscriptionAuthStep.Verifying && (
                    <ConnectingStateContainerStyled alignItems="center">
                        <ConnectingStatusStyled>{`Verifying TV subscription`}</ConnectingStatusStyled>
                        <ConnectingDetailStyled>
                            Confirming your TV channel access. Hang tight — this part can be a
                            little longer.
                        </ConnectingDetailStyled>
                    </ConnectingStateContainerStyled>
                )}
                {(step === SubscriptionAuthStep.Blocked ||
                    step === SubscriptionAuthStep.Verified) && (
                    <SuccessContainerStyled>
                        {step === SubscriptionAuthStep.Blocked ? (
                            <StatusNotificationStyled
                                format="warning"
                                icon={<AlertIcon />}
                                title="MISSING TV ACCESS"
                                body={`It looks like your ${platformName} subscription doesn’t include access to TV channels.`}
                            />
                        ) : (
                            <StatusNotificationStyled
                                format="success"
                                icon={<CheckIcon />}
                                title="TV ACCESS VERIFIED"
                                body={`Nice! You’ll now have access to any content included in your ${platformName} subscription.`}
                            />
                        )}
                        <FilledButton size="lg" format="success" onClick={onAddMore}>
                            Connect another account
                        </FilledButton>
                        <FilledButton size="lg" format="neutral_light" onClick={onExit}>
                            Exit
                        </FilledButton>
                    </SuccessContainerStyled>
                )}
            </AuthContainerStyled>
            {alwaysShowPortal && renderPortal()}
        </TVAuthStyled>
    );
};

const StatusNotificationStyled = styled(StatusNotification)`
    margin-bottom: ${rem(24)};
`;

const StatusIconStyled = styled(Centered)<{ succeeded: boolean; failed: boolean }>`
    height: ${rem(20)};
    width: ${rem(20)};
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.DarkGrey2};
    position: relative;

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
    }

    ${({ failed, succeeded, theme }) =>
        (failed || succeeded) &&
        css`
            background-color: ${succeeded ? theme.palette.Green : theme.palette.Yellow};
        `}
`;

const PrivateIconContainerStyled = styled.span`
    height: ${rem(16)};
    width: ${rem(16)};
    display: inline-flex;
    margin-right: ${rem(8)};
    color: ${({ theme }) => theme.palette.LightGrey1};

    svg {
        height: 100%;
        width: 100%;
    }
`;

const PrivacySubtitleStyled = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${({ theme }) => theme.typography.Paragraph13}
    text-align: left;
    margin-top: ${rem(12)};
`;

const SuccessContainerStyled = styled(Column)`
    align-self: stretch;
    padding: ${rem(24)} 0;

    button {
        width: 100%;
    }

    button + button {
        margin-top: ${rem(12)};
    }
`;

const ErrorMessageStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}
    color: ${({ theme }) => theme.palette.Red};
    margin-bottom: ${rem(24)};
    padding: 0 ${rem(40)};
    text-align: center;
`;

const AuthFormStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
`;

const PortalContainerStyled = styled.div<{ visible: boolean }>`
    align-self: flex-start;
    border-radius: ${rem(8)};
    overflow: hidden;
    box-shadow: inset 0 0 0 ${rem(1)} ${({ theme }) => theme.palette.MedGrey3};
    background-color: ${({ theme }) => theme.palette.DarkGrey3};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const ConnectingStatusStyled = styled.p`
    ${({ theme }) => theme.typography.Heading16}
    text-align: center;
    margin-bottom: ${rem(12)};
`;

const ConnectingDetailStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph16}
    text-align: center;
`;

const ConnectingStateContainerStyled = styled(Column)`
    margin: auto 0;
    padding: ${rem(28)};
`;

const PlatformNameStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading16}
    margin-bottom: ${rem(2)};
    text-align: center;
`;

const PlatformUrlStyled = styled.a`
    ${({ theme }) => theme.typography.Paragraph13}
    text-align: center;
    transition: color 50ms ease;

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.White};
            text-decoration: underline;
            text-underline-offset: ${rem(4)};
        }
    }
`;

const SpinnerStyled = styled(Spinner)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const PlatformIconStyled = styled.img`
    height: ${rem(68)};
    width: ${rem(120)};
`;

const PlatformIconContainerStyled = styled(Centered)<{
    mobile: boolean;
    failed: boolean;
    succeeded: boolean;
}>`
    height: ${({ mobile }) => rem(mobile ? 160 : 120)};
    width: ${({ mobile }) => rem(mobile ? 160 : 120)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    position: relative;
    margin-bottom: ${rem(12)};

    ${StatusIconStyled} {
        position: absolute;
        bottom: ${rem(4)};
        right: ${rem(4)};
    }

    ${({ failed, succeeded, theme }) =>
        (failed || succeeded) &&
        css`
            border: ${rem(2)} solid ${succeeded ? theme.palette.Green : theme.palette.Yellow};
        `}
`;

const ForgotPasswordStyled = styled.a`
    ${({ theme }) => theme.typography.Paragraph13}
    color: ${({ theme }) => theme.palette.Yellow};
    margin-top: ${rem(12)};

    &:hover {
        text-decoration: underline;
        text-underline-offset: ${rem(4)};
    }
`;

const TextInputStyled = styled(TextInput)`
    width: 100%;

    & + & {
        margin-top: ${rem(24)};
    }
`;

const AuthContainerStyled = styled(Column)`
    justify-content: flex-start;
    align-items: center;
    padding: ${rem(40)} 0;
    min-height: ${rem(295)};
`;

const PlatformInfoStyled = styled(Column)`
    align-self: center;
`;

const TVAuthStyled = styled(Column)`
    flex: 1;
    align-self: stretch;
`;

export default SubscriptionAuth;
