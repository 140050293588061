import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import UserIcon from '../../assets/svg/user.svg';

export enum AvatarSize {
    Small = 90,
    Medium = 120,
    Large = 140,
}

interface LargeAvatar {
    className?: string;
    size: number;
    avatarUrl?: string;
    mobile?: boolean;
}

const LargeAvatar = (props: LargeAvatar) => {
    const { className, size = AvatarSize.Medium, avatarUrl, mobile = false } = props;

    return (
        <ParticipantStyled className={className} visible={!!size}>
            <ParticipantInnerStyled size={size} mobile={mobile}>
                <UserIcon />
                {avatarUrl && (
                    <AvatarStyled>
                        <AvatarImageStyled src={avatarUrl} />
                    </AvatarStyled>
                )}
            </ParticipantInnerStyled>
        </ParticipantStyled>
    );
};

const AvatarImageStyled = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    border: ${rem(1)} solid ${({ theme }) => theme.palette.MedGrey4};
`;

const AvatarStyled = styled.div`
    position: absolute;
    z-index: 1;
    top: ${rem(-1)};
    right: ${rem(-1)};
    bottom: ${rem(-1)};
    left: ${rem(-1)};
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.palette.MedGrey4};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ParticipantInnerStyled = styled.div<{
    size: number;
    mobile: boolean;
}>`
    height: ${({ size }) => rem(size)};
    width: ${({ size }) => rem(size)};
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    transition: border-color 500ms ease;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.MedGrey4};

    svg {
        height: ${({ mobile }) => rem(mobile ? 64 : 40)};
        width: ${({ mobile }) => rem(mobile ? 64 : 40)};
        color: ${({ theme }) => theme.palette.LightGrey1};
    }
`;

const ParticipantStyled = styled.div<{ visible: boolean }>`
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;
    position: relative;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 100ms ease;

    @media screen and (orientation: landscape) {
        opacity: 1;
    }
`;

export default LargeAvatar;
