import styled, { css } from 'styled-components';
import { rem } from 'polished';

const DropdownList = styled.ul<{ mobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    overflow: hidden;

    ${({ mobile }) =>
        mobile &&
        css`
            margin-bottom: ${rem(-12)};
        `}
`;

export default DropdownList;
