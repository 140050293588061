import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

interface ITextArea {
    className?: string;
    label?: string;
    id?: string;
}

const TextArea: React.ForwardRefRenderFunction<
    HTMLTextAreaElement,
    ITextArea & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = (props, ref) => {
    const { className, id, label, ...rest } = props;

    return (
        <ContainerStyled className={className}>
            {label && (
                <LabelTextStyled className="pb500" htmlFor={id}>
                    {label}
                </LabelTextStyled>
            )}
            <TextAreaStyled ref={ref} {...rest} id={id} />
        </ContainerStyled>
    );
};

const LabelTextStyled = styled.label`
    padding-bottom: ${rem(8)};
    font-size: ${rem(13)};
    color: ${({ theme }) => theme.palette.White};
    line-height: 1.17;
    display: block;
`;

const TextAreaStyled = styled.textarea`
    resize: none;
    background-color: ${({ theme }) => theme.palette.DarkGrey3};
    border: ${rem(1)} solid ${({ theme }) => theme.palette.MedGrey4};
    border-radius: ${rem(8)};
    box-sizing: border-box;
    padding: ${rem(10)} ${rem(12)};
    color: ${({ theme }) => theme.palette.White};
    font-size: ${rem(13)};
    transition: border-color 100ms ease, color 100ms ease, opacity 100ms ease;
    width: 100%;
    height: 100%;

    &::placeholder {
        color: ${({ theme }) => theme.palette.LightGrey1};
        font-size: ${rem(13)};
    }

    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.palette.White};
    }

    &:disabled {
        opacity: 0.7;
    }
`;

const ContainerStyled = styled.div`
    flex: 1;
`;

export default forwardRef(TextArea);
