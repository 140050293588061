export const ROOM_URL =
    typeof window === 'undefined' ? 'https://www.getplayback.com/room' : `${location.origin}/room`;

export const MAX_MESSAGE_NOTIFICATIONS = 3;

export const FEEDBACK_CONFIG = {
    rating: {
        heading: 'How was your room experience?',
        subheading: 'Your feedback will help improve Playback',
    },
    feedbackPositive: {
        heading: 'Anything else you’d like to share?',
    },
    feedbackNegative: {
        optionsHeading: 'Did you experience any of the following?',
        options: [
            'Poor image quality',
            'Choppy video stream',
            'Difficulty seeing other viewers',
            'Difficulty hearing other viewers',
            'Other',
        ],
        feedbackHeading: 'Any other details you can share?',
    },
    thankYou: {
        heading: 'Thank you!',
        subheading: 'Your feedback is extremely helpful',
    },
};

export const FEEDBACK_DROPDOWN_CONFIG = {
    rating: {
        heading: 'How would you rate the room overall?',
    },
    feedback: {
        heading: 'Anything else you’d like to share?',
    },
    issues: {
        optionsHeading: 'Have you experienced any of the following?',
        options: [
            'Choppy video stream',
            'Unable to send or receive messages',
            'Difficulty seeing speakers',
            'Difficulty hearing speakers',
            'Other',
        ],
    },
    thankYou: {
        heading: 'Thank you!',
        subheading: 'We’ll review your feedback immediately.',
    },
};

export const FEEDBACK_CONFIG_IN_ROOM = {
    ...FEEDBACK_CONFIG,
    rating: {
        ...FEEDBACK_CONFIG.rating,
        heading: 'How’s your room going?',
    },
    feedbackNegative: {
        ...FEEDBACK_CONFIG.feedbackNegative,
        optionsHeading: 'Have you experienced any of the following?',
    },
};
