import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Column from 'packages/common/base/styles/components/Column';
import Centered from 'packages/common/base/styles/components/Centered';

interface StatusNotification {
    className?: string;
    icon: React.ReactNode;
    title: string;
    body: string;
    format: 'success' | 'warning';
}

const StatusNotification: React.FunctionComponent<StatusNotification> = (props) => {
    const { className, icon, title, body, format } = props;

    return (
        <StatusNotificationStyled className={className} format={format}>
            <IconContainerStyled>{icon}</IconContainerStyled>
            <NotificationHeadingStyled>{title}</NotificationHeadingStyled>
            <NotificationBodyStyled>{body}</NotificationBodyStyled>
        </StatusNotificationStyled>
    );
};

const IconContainerStyled = styled(Centered)`
    height: ${rem(20)};
    width: ${rem(20)};
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.DarkGrey2};
    position: relative;

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
    }
`;

const NotificationBodyStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}
    color: inherit;
`;

const NotificationHeadingStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading13}
    ${({ theme }) => theme.typography.FontBold}
    color: inherit;
    margin-bottom: ${rem(8)};
`;

const StatusNotificationStyled = styled(Column)<Pick<StatusNotification, 'format'>>`
    background-color: ${({ theme, format }) =>
        format === 'success' ? theme.palette.GreenTransparent1 : theme.palette.YellowTransparent1};
    padding: ${rem(16)};
    border-radius: ${rem(8)};
    overflow: hidden;
    color: ${({ theme, format }) =>
        format === 'success' ? theme.palette.Green : theme.palette.Yellow};

    ${IconContainerStyled} {
        margin-bottom: ${rem(12)};
        background-color: ${({ theme, format }) =>
            format === 'success' ? theme.palette.Green : theme.palette.Yellow};
    }
`;

export default StatusNotification;
