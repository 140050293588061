import { Platforms } from 'packages/common/tv-auth';

export const WS_HOST =
    process.env.NEXT_PUBLIC_ENV === 'production'
        ? 'wss://tv-auth.getplayback.com'
        : 'wss://tv-auth.getplayback.dev';

export type PlatformAuthConfig = {
    platform: Platforms;
    icon: string;
    displayUrl: string;
    loginUrl: string;
    passwordResetUrl: string;
    usernameType: 'email' | 'text';
    usernameCopy: string;
    usernamePlaceholder?: string;
};

export const platforms: PlatformAuthConfig[] = [
    {
        icon: '/img/apps/platforms/youtubetv.png',
        platform: Platforms.YouTubeTV,
        displayUrl: 'tv.youtube.com',
        loginUrl:
            'https://accounts.google.com/signin/v2/identifier?continue=http%3A%2F%2Fm.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26feature%3Dupg_web_login%26skip_identity_prompt%3DTrue%26hl%3Den%26next%3Dhttps%253A%252F%252Ftv.youtube.com%252F%253Futm_servlet%253Dexp%2526onboard%253D3&service=youtube&sacu=1&hl=en&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin',
        passwordResetUrl:
            'https://accounts.google.com/signin/v2/identifier?continue=http%3A%2F%2Fm.youtube.com%2Fsignin%3Faction_handle_signin%3Dtrue%26feature%3Dupg_web_login%26skip_identity_prompt%3DTrue%26hl%3Den%26next%3Dhttps%253A%252F%252Ftv.youtube.com%252F%253Futm_servlet%253Dexp%2526onboard%253D3&service=youtube&sacu=1&hl=en&rip=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        icon: '/img/apps/platforms/nba.png',
        platform: Platforms.NBALeaguePass,
        displayUrl: 'watch.nba.com',
        loginUrl: 'https://watch.nba.com',
        passwordResetUrl: 'https://watch.nba.com',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        icon: '/img/apps/platforms/fubo.png',
        platform: Platforms.FuboTV,
        displayUrl: 'fubo.tv',
        loginUrl: 'https://www.fubo.tv/signin',
        passwordResetUrl: 'https://www.fubo.tv/signin',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    {
        icon: '/img/apps/platforms/sling.png',
        platform: Platforms.SlingTV,
        displayUrl: 'sling.com',
        loginUrl: 'https://www.sling.com/sign-in',
        passwordResetUrl: 'https://www.sling.com/sign-in/forgot-password',
        usernameType: 'email',
        usernameCopy: 'Email address',
    },
    // {
    //     icon: '/img/apps/platforms/xfinity.png',
    //     platform: Platforms.Xfinity,
    //     displayUrl: 'xfinity.com',
    //     loginUrl: 'https://login.xfinity.com/',
    //     passwordResetUrl: 'https://idm.xfinity.com/myaccount/lookup',
    //     usernameType: 'text',
    //     usernameCopy: 'Xfinity ID',
    //     usernamePlaceholder: 'Email, mobile, or username',
    // },
    {
        icon: '/img/apps/platforms/optimum.png',
        platform: Platforms.Optimum,
        displayUrl: 'optimum.net',
        loginUrl: 'https://www.optimum.net/login/',
        passwordResetUrl: 'https://www.optimum.net/reset-password/',
        usernameType: 'text',
        usernameCopy: 'Optimum ID',
    },
    // {
    //     icon: '/img/apps/platforms/spectrum.png',
    //     platform: Platforms.Spectrum,
    //     displayUrl: 'spectrum.net',
    //     loginUrl: 'https://id.spectrum.net/login',
    //     passwordResetUrl: 'https://id.spectrum.net/recover',
    //     usernameType: 'text',
    //     usernameCopy: 'Username',
    // },
    {
        icon: '/img/apps/platforms/directv.png',
        platform: Platforms.DIRECTV,
        displayUrl: 'directv.com',
        loginUrl:
            'https://signin.att.com/dynamic/iamLRR/LrrController?IAM_OP=login&appName=m45917&loginSuccessURL=https%3A%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps%3A%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps%3A%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp',
        passwordResetUrl:
            'https://identity.att.com/identity-ui/fpwd/lander?origination_point=tguard&trid=bea22b253a734ffcdadf8d50c7e04c8b776e5ab9&appName=m45917&Return_URL=https:%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps:%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps:%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp&Cancel_URL=https:%2F%2Foidc.idp.clogin.att.com%2Fmga%2Fsps%2Foauth%2Foauth20%2Fauthorize%3Fresponse_type%3Did_token%26client_id%3Dm45917%26state%3DHALODTV%26scope%3Dopenid%26nonce%3Dsdfdds8%26response_mode%3Dform_post%26redirect_uri%3Dhttps:%2F%2Fwww.directv.com%2Fmsapi%2Fdtvloginms%2Fv1%2Fauthenticate%3FnextUrl%3Dhttps:%2F%2Fwww.directv.com%2FDTVAPP%2Fauth.jsp&lang=en-us',
        usernameType: 'text',
        usernameCopy: 'User ID',
    },
    {
        icon: '/img/apps/platforms/cox.png',
        platform: Platforms.Cox,
        displayUrl: 'cox.com',
        loginUrl: 'https://www.cox.com/content/dam/cox/okta/signin.html',
        passwordResetUrl: 'https://www.cox.com/myprofile/forgot-password.html?finalview=',
        usernameType: 'text',
        usernameCopy: 'User ID',
    },
];

export const comingSoon = [
    { icon: '/img/apps/platforms/hulu.png', name: 'Hulu' },
    { icon: '/img/apps/platforms/spectrum.png', name: 'Spectrum' },
    { icon: '/img/apps/platforms/mlb.png', name: 'MLB TV' },
    { icon: '/img/apps/platforms/xfinity.png', platform: 'Xfinity' },
];
