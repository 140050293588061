import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import CloseButton from 'packages/common/base/components/CloseButton';
import {
    showModal,
    submitFeedback,
    showAccountPanel,
    hideAccountPanel,
    track,
    showNotification,
} from '../../state/app';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import Menu from './Menu';
import Account from './Account';
import Feedback from './Feedback';
import Subscriptions, { SubscriptionsScreen } from './Subscriptions';
import useAuth from '../../hooks/useAuth';
import {
    RequestUserAvatarDELETE,
    userAvatarDELETE,
    userGET,
} from 'packages/common/api-client/user';
import { init as initUser } from 'packages/common/state/user';
import { API_HOST } from '../../typescript/api';
import { toggleMessageNotifications } from 'packages/common/state/messages';
import { leaveRoom } from 'packages/common/state/room';
import { ModalType, TrackingContext } from '../../typescript/typings';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Client } from 'packages/common/tv-auth/client';
import { NotificationType } from 'packages/common/base/types';

interface AccountPanel {
    className?: string;
}

export enum AccountPanelScreen {
    Menu = 'Menu',
    Account = 'Account',
    Subscriptions = 'Subscriptions',
    Feedback = 'Feedback',
}

const AccountPanel: React.FunctionComponent<AccountPanel> = (props) => {
    const { className } = props;

    const ref = useRef<HTMLDivElement>(null);
    const tvAuthClient = useRef<Client>(null);

    const dispatch = useAppDispatch();
    const { user, logout, changeUserName, withApiAuth } = useAuth();

    const chatNotificationsEnabled = useAppSelector((state) => state.messages.notificationsEnabled);
    const screen = useAppSelector((state) => state.app.accountPanelScreen);
    const mobile = useAppSelector((state) => state.app.mobile);

    const [subscriptionsScreen, setSubscriptionsScreen] = useState<SubscriptionsScreen>(
        SubscriptionsScreen.List
    );

    const handleRemoveAvatar = async () => {
        if (confirm('Are you sure you want to remove your current avatar?')) {
            dispatch(track({ event: 'Remove Avatar' }));
            const req: RequestUserAvatarDELETE = {
                accessToken: user.accessToken,
            };
            await withApiAuth(userAvatarDELETE(req, API_HOST));
            const apiUser = await userGET(user.accessToken, API_HOST);
            dispatch(initUser(apiUser));
        }
    };

    const handleToggleChatNotifications = () => {
        dispatch(
            track({
                event: chatNotificationsEnabled
                    ? 'Disable Chat Notifications'
                    : 'Enable Chat Notifications',
            })
        );

        dispatch(toggleMessageNotifications());
    };

    const handleSignup = (context?: TrackingContext) => {
        dispatch(showModal({ type: ModalType.Signup }));
        dispatch(
            track({
                event: 'Click Sign Up',
                context,
            })
        );
    };

    const handleLogin = (context?: TrackingContext) => {
        dispatch(showModal({ type: ModalType.Login }));
        dispatch(
            track({
                event: 'Click Log In',
                context,
            })
        );
    };

    const handleLogout = () => {
        dispatch(leaveRoom());
        logout();
    };

    const handleClose = () => {
        dispatch(hideAccountPanel());
        setSubscriptionsScreen(SubscriptionsScreen.List);
        tvAuthClient.current?.close();
    };

    const visible = screen !== null;

    useEffect(() => {
        if (visible) {
            disableBodyScroll(ref.current);

            return () => {
                enableBodyScroll(ref.current);
            };
        }
    }, [visible]);

    return (
        <AccountPanelStyled className={className} ref={ref} visible={visible} onClick={handleClose}>
            <PanelStyled
                mobile={mobile}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                visible={visible}
            >
                <CloseButtonStyled size="lg" onClick={handleClose} />
                <Menu
                    visible={visible && screen === AccountPanelScreen.Menu}
                    onClickItem={(screen) => {
                        dispatch(track({ event: 'Open Account Panel Screen', screen }));
                        dispatch(showAccountPanel(screen));
                    }}
                    onClickDiscord={() => {
                        dispatch(track({ event: 'Click Account Panel Discord Link' }));
                    }}
                />
                <Subscriptions
                    visible={visible && screen === AccountPanelScreen.Subscriptions}
                    screen={subscriptionsScreen}
                    authClient={tvAuthClient}
                    onClose={() => {
                        dispatch(showAccountPanel(AccountPanelScreen.Menu));
                    }}
                    onExit={handleClose}
                    setScreen={setSubscriptionsScreen}
                />
                <Account
                    visible={visible && screen === AccountPanelScreen.Account}
                    onChangeName={changeUserName}
                    onSignup={handleSignup}
                    onLogin={handleLogin}
                    onLogout={handleLogout}
                    onToggleNotifications={handleToggleChatNotifications}
                    onRemoveAvatar={handleRemoveAvatar}
                    onClose={() => {
                        dispatch(showAccountPanel(AccountPanelScreen.Menu));
                    }}
                />
                <Feedback
                    visible={visible && screen === AccountPanelScreen.Feedback}
                    onClose={() => {
                        dispatch(showAccountPanel(AccountPanelScreen.Menu));
                    }}
                    onSubmitFeedback={(feedback: any, inRoom = true) => {
                        dispatch(submitFeedback({ feedback, inRoom }));
                        dispatch(
                            track({
                                event: 'User Feedback',
                                ...feedback,
                                role: 'Viewer',
                                end: !inRoom,
                            })
                        );
                    }}
                />
            </PanelStyled>
        </AccountPanelStyled>
    );
};

const CloseButtonStyled = styled(CloseButton)`
    position: absolute;
    top: ${rem(12)};
    right: ${rem(16)};
`;

const PanelStyled = styled.div<{ mobile: boolean; visible: boolean }>`
    width: ${rem(400)};
    background-color: ${({ theme }) => theme.palette.DarkGrey1};
    ${({ theme }) => theme.common.StandardBorder('left')}
    position: absolute;
    top: 0;
    right: ${rem(-400)};
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    transition: transform 100ms ease;

    ${({ visible }) =>
        visible &&
        css`
            transform: translateX(${rem(-400)});
        `}

    ${({ mobile, visible }) =>
        mobile &&
        css`
            width: auto;
            left: 0;
            top: 0;
            right: 0;
            transform: translateY(${visible ? 0 : '100%'});
        `}
`;

const AccountPanelStyled = styled.div<{ visible: boolean }>`
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    transition: background-color 100ms ease, transform 0s linear 100ms;
    pointer-events: none;
    transform: translateY(100%);

    ${({ visible }) =>
        visible &&
        css`
            background-color: rgba(0, 0, 0, 0.5);
            pointer-events: auto;
            transform: translateY(0);
            transition: background-color 100ms ease, transform 0s linear 0s;
        `}
`;

export default AccountPanel;
