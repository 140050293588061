import React from 'react';
import CloseIcon from '../../assets/svg/close.svg';
import FilledButton from './FilledButton';

interface CloseButton {
    className?: string;
    transparent?: boolean;
    tabIndex?: number;
    size?: FilledButton['size'];
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CloseButton: React.FunctionComponent<CloseButton> = (props) => {
    const { className, transparent = false, tabIndex, size = 'md', onClick } = props;

    return (
        <FilledButton
            className={className}
            tabIndex={tabIndex}
            onClick={onClick}
            type="button"
            size={size}
            icon
            transparent={transparent}
        >
            <CloseIcon />
        </FilledButton>
    );
};

export default CloseButton;
