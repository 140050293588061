import React from 'react';
import styled, { css } from 'styled-components';
import { rem, transparentize } from 'polished';
import Check from '../../assets/svg/check.svg';

interface ICheckSelectInput {
    label?: React.ReactNode;
    format?: 'positive' | 'negative';
}

const CheckSelectInput: React.FunctionComponent<
    ICheckSelectInput & React.ComponentPropsWithRef<'input'>
> = ({ className, id, checked, label, format = 'positive', ...props }) => (
    <CheckSelectInputContainer className={className} htmlFor={id}>
        <CheckboxInputStyled id={id} checked={checked} format={format} {...props} />
        {label}
    </CheckSelectInputContainer>
);

interface Checkbox extends React.ComponentPropsWithRef<'input'> {
    format?: ICheckSelectInput['format'];
}

export const Checkbox: React.FC<Checkbox> = (props) => {
    const { className, id, checked, format, ...rest } = props;

    return (
        <CheckboxStyled className={className}>
            <HiddenInput id={id} type="checkbox" checked={checked} {...rest} />
            <CheckboxContainerStyled checked={checked} format={format}>
                <Check />
            </CheckboxContainerStyled>
        </CheckboxStyled>
    );
};

const CheckboxInputStyled = styled(Checkbox)`
    margin-right: ${rem(8)};
`;

const CheckboxStyled = styled.div``;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenInput = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const CheckboxContainerStyled = styled.div<{ checked: boolean; format: 'positive' | 'negative' }>`
    display: inline-block;
    width: ${rem(20)};
    height: ${rem(20)};
    border: ${rem(1)} solid
        ${({ theme, checked }) => (checked ? 'transparent' : theme.palette.LightGrey1)};
    border-radius: ${rem(4)};
    color: ${({ theme }) => theme.palette.Green};
    background-color: ${({ checked, theme }) =>
        checked
            ? theme.palette.GreenTransparent1
            : transparentize(1, theme.palette.GreenTransparent1)};
    box-sizing: border-box;
    transition: border-color 50ms ease, background-color 50ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    .focus-visible + & {
        border-color: ${({ theme }) => theme.palette.White};
    }

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
    }

    ${({ checked }) =>
        !checked &&
        css`
            svg {
                display: none;
            }
        `}

    ${({ format, checked, theme }) =>
        format === 'negative' &&
        css`
            color: ${theme.palette.Red};
            border: ${rem(1)} solid ${checked ? 'transparent' : theme.palette.LightGrey1};
            background-color: ${checked
                ? theme.palette.RedTransparent1
                : transparentize(1, theme.palette.RedTransparent1)};
        `}
`;

const CheckSelectInputContainer = styled.label`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.White};
    font-size: ${rem(13)};

    &:disabled {
        cursor: default;

        ${CheckboxContainerStyled} {
            cursor: default;
        }
    }

    & + & {
        margin-top: ${rem(16)};
    }
`;

export default CheckSelectInput;
