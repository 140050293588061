import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { rem } from 'polished';
import useFadeOut from '../hooks/useFadeOut';

interface Spinner {
    className?: string;
    duration?: number;
    size?: 'sm' | 'lg';
    color?: string;
    visible?: boolean;
}

const Spinner: React.FunctionComponent<Spinner> = (props) => {
    const { className, duration = 1333, size = 'sm', color, visible = true } = props;

    const isShowing = useFadeOut({ visible, duration: 200 });

    if (!isShowing) {
        return null;
    }

    return (
        <SpinnerStyled
            className={className}
            duration={duration}
            size={size}
            color={color}
            visible={isShowing}
        >
            <RingStyled />
            <RingStyled />
            <RingStyled />
            <RingStyled />
        </SpinnerStyled>
    );
};

const Spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const RingStyled = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    border-radius: 50%;
`;

const SpinnerStyled = styled.div<{
    duration: number;
    size: 'sm' | 'lg';
    visible: boolean;
    color?: string;
}>`
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
    animation: ${Rotate} ${({ duration }) => duration * 3}ms linear infinite;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 200ms ease;

    ${RingStyled} {
        animation: ${Spin} ${({ duration }) => duration}ms cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border: ${({ size }) => rem(size === 'sm' ? 2 : 5)} solid
            ${({ theme, color }) => color || theme.palette.Yellow};
        border-color: ${({ theme, color }) => color || theme.palette.Yellow} transparent transparent
            transparent;
    }

    ${({ duration }) => css`
        ${RingStyled}:nth-child(1) {
            animation-delay: -${0.375 * duration}ms;
        }

        ${RingStyled}:nth-child(2) {
            animation-delay: -${0.25 * duration}ms;
        }

        ${RingStyled}:nth-child(3) {
            animation-delay: -${0.125 * duration}ms;
        }
    `}
`;

export default Spinner;
