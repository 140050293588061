import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

interface EmptyState {
    className?: string;
    title: string;
    description: string;
    icon: React.ReactNode;
}

const EmptyState: React.FunctionComponent<EmptyState> = (props) => {
    const { className, title, description, icon } = props;

    return (
        <EmptyStateStyled className={className}>
            {icon}
            <HeadingStyled>{title}</HeadingStyled>
            <DescriptionStyled>{description}</DescriptionStyled>
        </EmptyStateStyled>
    );
};

const HeadingStyled = styled.h2`
    ${({ theme }) => theme.typography.Heading24};
    margin-top: ${rem(16)};
`;

const DescriptionStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13};
    margin-top: ${rem(16)};
    line-height: 1.4;
`;

const EmptyStateStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: ${rem(270)};
    margin: ${rem(30)} auto ${rem(60)};
    height: 100%;
    flex: 1;

    svg {
        height: ${rem(24)};
        width: ${rem(24)};
        color: ${({ theme }) => theme.palette.White};
    }
`;

export default EmptyState;
