import { useEffect, useRef, useState } from 'react';

interface useFadeOut {
    visible: boolean;
    duration: number;
}

const useFadeOut = ({ visible, duration }: useFadeOut) => {
    const [isShowing, setIsShowing] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        if (visible) {
            setIsShowing(true);

            return () => {
                timeout.current = setTimeout(() => {
                    setIsShowing(false);
                }, duration);
            };
        }
    }, [visible, duration]);

    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);

    return isShowing || visible;
};

export default useFadeOut;
