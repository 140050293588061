import styled, { css } from 'styled-components';
import { rem } from 'polished';

const DropdownContents = styled.div<{ mobile?: boolean }>`
    width: ${rem(200)};
    box-sizing: border-box;
    padding: ${rem(8)};
    border-radius: ${rem(8)};
    background-color: ${({ theme }) => theme.palette.DarkGrey1};
    border: ${rem(1)} solid ${({ theme }) => theme.palette.MedGrey4};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${({ theme }) => theme.common.BoxShadowLight};

    ${({ mobile }) =>
        mobile &&
        css`
            width: auto;
            padding: ${rem(40)};
            border-top-left-radius: ${rem(20)};
            border-top-right-radius: ${rem(20)};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: none;
            box-shadow: none;
        `}
`;

export default DropdownContents;
