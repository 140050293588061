import { debounce, once } from './util';

import { ProtocolCommands, HostCommands, Message, WorkerCommands } from './types';
import { Platforms, vpnRequiredPlatforms } from 'packages/common/tv-auth/platforms';
import { WS_HOST } from '../constants/tvAuth';

const errorHTML = (error: string) =>
    `<div class="fixed-message"><code style="color: red">${error.toString()}</code></div>`;

interface RunnerParams {
    code: string;
    onClose: (...args: any[]) => void;
}

export default class Runner {
    private puppeteerWorker!: Worker;

    private readonly onClose: RunnerParams['onClose'];

    private portalID: string;

    private remote: boolean;

    private useVPN: boolean;

    private $canvas: HTMLCanvasElement;

    private ctx!: CanvasRenderingContext2D;

    private img = new Image();

    private started = false;

    static getModifiersForEvent(event: any) {
        return (
            // eslint-disable-next-line no-bitwise
            (event.altKey ? 1 : 0) |
            (event.ctrlKey ? 2 : 0) |
            (event.metaKey ? 4 : 0) |
            (event.shiftKey ? 8 : 0)
        );
    }

    constructor({
        portalID,
        canvas,
        onClose,
        remote,
        useVPN,
    }: {
        portalID: string;
        canvas: HTMLCanvasElement;
        onClose: () => void;
        remote: boolean;
        useVPN: boolean;
    }) {
        this.onClose = onClose;
        this.$canvas = canvas;
        this.portalID = portalID;
        this.remote = remote;
        this.useVPN = useVPN;

        this.setupPuppeteerWorker();
    }

    emitMouse = (evt: any) => {
        const buttons: any = { 0: 'none', 1: 'left', 2: 'middle', 3: 'right' };
        const event: any = evt.type === 'mousewheel' ? window.event || evt : evt;
        const types: any = {
            mousedown: 'mousePressed',
            mouseup: 'mouseReleased',
            mousewheel: 'mouseWheel',
            touchstart: 'mousePressed',
            touchend: 'mouseReleased',
            touchmove: 'mouseWheel',
            mousemove: 'mouseMoved',
        };

        if (!(event.type in types)) {
            return;
        }

        if (
            event.type !== 'mousewheel' &&
            buttons[event.which] === 'none' &&
            event.type !== 'mousemove'
        ) {
            return;
        }

        const type = types[event.type] as string;
        const isScroll = type.indexOf('wheel') !== -1;
        const x = isScroll ? event.clientX : event.offsetX;
        const y = isScroll ? event.clientY : event.offsetY;

        const data: Record<string, any> = {
            type: types[event.type],
            x,
            y,
            modifiers: Runner.getModifiersForEvent(event),
            button: event.type === 'mousewheel' ? 'none' : buttons[event.which],
            clickCount: 1,
        };

        if (event.type === 'mousewheel') {
            data.deltaX = event.wheelDeltaX || 0;
            data.deltaY = event.wheelDeltaY || event.wheelDelta;
        }

        this.puppeteerWorker.postMessage({
            command: ProtocolCommands['Input.emulateTouchFromMouseEvent'],
            data,
        });
    };

    emitKeyEvent = (event: KeyboardEvent) => {
        let type;

        // Prevent backspace from going back in history
        if (event.keyCode === 8) {
            event.preventDefault();
        }

        switch (event.type) {
            case 'keydown':
                type = 'keyDown';
                break;
            case 'keyup':
                type = 'keyUp';
                break;
            case 'keypress':
                type = 'char';
                break;
            default:
                return;
        }

        const text = type === 'char' ? String.fromCharCode(event.charCode) : undefined;
        const data = {
            type,
            text,
            unmodifiedText: text ? text.toLowerCase() : undefined,
            keyIdentifier: (event as any).keyIdentifier,
            code: event.code,
            key: event.key,
            windowsVirtualKeyCode: event.keyCode,
            nativeVirtualKeyCode: event.keyCode,
            autoRepeat: false,
            isKeypad: false,
            isSystemKey: false,
        };

        this.puppeteerWorker.postMessage({
            command: ProtocolCommands['Input.dispatchKeyEvent'],
            data,
        });
    };

    onScreencastFrame = (data: string) => {
        this.img.onload = () => {
            // console.log(
            //     'Runner onScreencastFrame onload, ctx:',
            //     this.$canvas.width,
            //     this.$canvas.height
            // );
            this.ctx.drawImage(this.img, 0, 0, this.$canvas.width, this.$canvas.height);
        };
        this.img.src = `data:image/png;base64,${data}`;
    };

    bindKeyEvents = () => {
        document.body.addEventListener('keydown', this.emitKeyEvent, true);
        document.body.addEventListener('keyup', this.emitKeyEvent, true);
        document.body.addEventListener('keypress', this.emitKeyEvent, true);
    };

    unbindKeyEvents = () => {
        document.body.removeEventListener('keydown', this.emitKeyEvent, true);
        document.body.removeEventListener('keyup', this.emitKeyEvent, true);
        document.body.removeEventListener('keypress', this.emitKeyEvent, true);
    };

    addListeners = () => {
        this.$canvas.addEventListener('mousedown', this.emitMouse, false);
        this.$canvas.addEventListener('mouseup', this.emitMouse, false);
        this.$canvas.addEventListener('mousewheel', this.emitMouse, false);
        this.$canvas.addEventListener('mousemove', this.emitMouse, false);

        this.$canvas.addEventListener('mouseenter', this.bindKeyEvents, false);
        this.$canvas.addEventListener('mouseleave', this.unbindKeyEvents, false);

        window.addEventListener('resize', this.resizePage);
    };

    removeEventListeners = () => {
        if (!this.started) return;
        this.$canvas.removeEventListener('mousedown', this.emitMouse, false);
        this.$canvas.removeEventListener('mouseup', this.emitMouse, false);
        this.$canvas.removeEventListener('mousewheel', this.emitMouse, false);
        this.$canvas.removeEventListener('mousemove', this.emitMouse, false);

        this.$canvas.removeEventListener('mouseenter', this.bindKeyEvents, false);
        this.$canvas.removeEventListener('mouseleave', this.unbindKeyEvents, false);

        window.removeEventListener('resize', this.resizePage);
    };

    resizePage = debounce(() => {
        const { width, height } = this.$canvas.getBoundingClientRect();
        this.sendWorkerMessage({
            command: 'setViewport',
            data: {
                width: Math.floor(width),
                height: Math.floor(height),
            },
        });
    }, 500);

    close = once((...args: any[]) => {
        this.onClose(...args);
        this.sendWorkerMessage({ command: HostCommands.close, data: null });
        this.removeEventListeners();
        this.unbindKeyEvents();
    });

    showError = (err: string) => {
        // this.$mount.innerHTML = `${errorHTML(err)}`;
    };

    sendWorkerMessage = (message: Message) => {
        this.puppeteerWorker.postMessage(message);
    };

    onWorkerSetupComplete = (payload: Message['data']) => {
        this.started = true;
        this.ctx = this.$canvas.getContext('2d') as CanvasRenderingContext2D;

        this.addListeners();
        this.resizePage();
    };

    setupPuppeteerWorker = () => {
        this.puppeteerWorker = new Worker(new URL('./puppeteer.worker.ts', import.meta.url));

        // eslint-disable-next-line consistent-return
        this.puppeteerWorker.addEventListener('message', (evt) => {
            const { command, data } = evt.data as Message;

            if (command === WorkerCommands.startComplete) {
                return this.onWorkerSetupComplete(data);
            }

            if (command === WorkerCommands.screencastFrame) {
                return this.onScreencastFrame(data);
            }

            if (command === WorkerCommands.error) {
                return this.showError(data);
            }

            if (command === WorkerCommands.browserClose) {
                return this.showError(`Session complete! Browser has closed.`);
            }
        });

        this.puppeteerWorker.addEventListener('error', ({ message }) => {
            this.puppeteerWorker.terminate();
            return this.showError(`Error communicating with puppeteer-worker ${message}`);
        });

        let browserWSEndpoint = `ws://localhost:3000/ws/${this.portalID}`;
        if (this.remote) {
            browserWSEndpoint = `${WS_HOST}/portal/${this.portalID}`;
            if (this.useVPN) {
                browserWSEndpoint = `${WS_HOST}/vpn/portal/${this.portalID}`;
            }
        }

        this.sendWorkerMessage({
            command: 'start',
            data: {
                browserWSEndpoint,
                quality: 100,
                targetId: this.portalID,
            },
        });
    };
}
