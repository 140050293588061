import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import TextInput, { ITextInput } from './TextInput';
import FilledButton from './FilledButton';
import CheckIcon from '../../assets/svg/check.svg';
import EditIcon from '../../assets/svg/edit.svg';

interface EditableTextInput extends ITextInput {
    onSave: (value: string) => void;
    onClick?: () => void;
    value: string;
}

const EditableTextInput: React.FunctionComponent<EditableTextInput> = (props) => {
    const {
        className,
        value,
        minLength,
        maxLength,
        children,
        onSave,
        onChange,
        onKeyDown,
        onFocus,
        onClick,
        ...rest
    } = props;

    const [localValue, setLocalValue] = useState(value);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState(null);

    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const cancelChanges = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setLocalValue(value);
        setEditing(false);
        setError(null);
        input.current.blur();
    };

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (minLength && localValue.length < minLength) {
            setError(`Username must be at least ${minLength} characters long`);
            return;
        }

        if (maxLength && localValue.length > maxLength) {
            setError(`Username must be at most ${maxLength} characters long`);
            return;
        }

        onSave(localValue);
        setEditing(false);
        input.current.blur();
    };

    return (
        <EditableTextInputStyled
            className={className}
            noValidate
            onSubmit={submit}
            onClick={onClick}
        >
            <TextInputContainerStyled>
                <TextInputStyled
                    ref={input}
                    value={localValue}
                    type="text"
                    minLength={minLength}
                    maxLength={maxLength}
                    onFocus={(e) => {
                        setEditing(true);
                        if (onFocus) {
                            onFocus(e);
                        }
                    }}
                    onChange={(e) => {
                        setLocalValue(e.target.value);
                        setError(null);
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                            cancelChanges();
                        }

                        if (onKeyDown) {
                            onKeyDown(e);
                        }
                    }}
                    icon={editing ? null : <EditIcon />}
                    {...rest}
                />
            </TextInputContainerStyled>
            {error && <ErrorStyled>{error}</ErrorStyled>}
            {editing && (
                <ButtonsStyled>
                    <FilledButton
                        size="md"
                        format="success"
                        type="submit"
                        disabled={!localValue || !!error}
                    >
                        <CheckIcon />
                        Save
                    </FilledButton>
                    <FilledButton size="md" type="button" format="negative" onClick={cancelChanges}>
                        Cancel
                    </FilledButton>
                </ButtonsStyled>
            )}
            {children}
        </EditableTextInputStyled>
    );
};

const ErrorStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}
    text-align: flex-start;
    margin-top: ${rem(8)};
    color: ${({ theme }) => theme.palette.Red};
`;

const TextInputStyled = styled(TextInput)`
    input {
        &:not(:focus) {
            cursor: pointer;

            &:hover {
                box-shadow: inset 0 0 0 ${rem(1)} ${({ theme }) => theme.palette.MedGrey4};
            }
        }
    }
`;

const TextInputContainerStyled = styled.div``;

const ButtonsStyled = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${rem(12)};

    > * + * {
        margin-left: ${rem(8)};
    }
`;

const EditableTextInputStyled = styled.form`
    position: relative;
`;

export default EditableTextInput;
