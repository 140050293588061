import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface ToggleSwitch {
    className?: string;
    id?: string;
    toggled: boolean;
    disabled?: boolean;
    onChange: (on: boolean) => void;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToggleSwitch: React.FunctionComponent<ToggleSwitch> = (props) => {
    const { className, id, disabled = false, toggled, onChange, onClick } = props;

    return (
        <ToggleSwitchStyled
            className={className}
            id={id}
            type="button"
            toggled={toggled}
            disabled={disabled}
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                }

                if (!disabled) {
                    onChange(!toggled);
                }
            }}
        >
            <SliderStyled />
        </ToggleSwitchStyled>
    );
};

const SliderStyled = styled.div`
    height: ${rem(16)};
    width: ${rem(16)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.White};
    position: absolute;
    top: ${rem(2)};
    left: ${rem(2)};
    transform: translateX(0);
    transition: transform 150ms ease;
    cursor: inherit;
`;

const ToggleSwitchStyled = styled.button<{ toggled: boolean }>`
    height: ${rem(20)};
    width: ${rem(32)};
    border-radius: ${rem(13)};
    overflow: hidden;
    position: relative;
    transition: background-color 150ms ease;
    flex-shrink: 0;
    background-color: ${({ toggled, theme }) =>
        toggled ? theme.palette.Green : theme.palette.Black};

    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &.focus-visible {
        box-shadow: 0px 0px 0px ${rem(1)} ${({ theme }) => theme.palette.White};
    }

    ${({ toggled }) =>
        toggled &&
        css`
            ${SliderStyled} {
                transform: translateX(${rem(12)});
            }
        `}
`;

export default ToggleSwitch;
